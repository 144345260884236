import { AutoAwesome, Light, Storage, TipsAndUpdates } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import { defaultExifValue } from 'app/views/exif/AddExifDialog';
import exifAtom from 'app/views/exif/exifAtom';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

const EXIF = ({ userData, updateSite, site }) => {
  const [exifData, setExifData] = useRecoilState(exifAtom);
  const { user } = useAuth();
  const [openExifChooser, setOpenExifChooser] = useState(false);
  const [aiExifLoading, setAiExifLoading] = useState(false);

  const getAIExif = async () => {
    setAiExifLoading(true);
    try {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const { data } = await fn({
        openAIKey: userData.openai_key,
        command: 'getAIExif',
        language: site.settings.exif?.language || ''
      });

      updateSite({ settings: { exif: { ...data } } });
    } catch (e) {
      console.error(e);
      toast.error('Failed to add EXIF data from ai');
    }
    setAiExifLoading(false);
  };

  const handleChange = (e) => {
    updateSite({ settings: { exif: { [e.target.name]: e.target.value } } });
  };

  useEffect(() => {
    const fetchData = async () => {
      const exifs = [];
      const querySnapshot = await getDocs(
        collection(getFirestore(), 'userData', user.uid, 'exifs')
      );
      querySnapshot.forEach((doc) => {
        exifs.push({ id: doc.id, ...doc.data() });
      });
      setExifData(exifs);
    };

    if (!exifData) fetchData();
  }, [exifData]);

  return (
    <Card>
      <Typography variant="h6" sx={{ color: '#1976d2', padding: '12px 16px', fontWeight: 500 }}>
        EXIF
      </Typography>
      <Divider />
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: 'flex',
              padding: '5px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenExifChooser(true)}
              startIcon={<Storage />}
            >
              My EXIFs
            </Button>

            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => updateSite({ settings: { exif: { ...defaultExifValue } } })}
              >
                clear
              </Button>

              <TextField
                select
                size="small"
                label="AI EXIF language"
                value={!site.settings.exif?.language ? 'sm' : site.settings.exif?.language}
                onChange={(e) =>
                  updateSite({
                    settings: { exif: { language: e.target.value === 'sm' ? '' : e.target.value } }
                  })
                }
              >
                <MenuItem value="sm">Same as prompt</MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="German">German</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
                <MenuItem value="Italian">Italian</MenuItem>
                <MenuItem value="Dutch">Dutch</MenuItem>
                <MenuItem value="Portuguese">Portuguese</MenuItem>
              </TextField>
              <LoadingButton
                variant="contained"
                loading={aiExifLoading}
                color="primary"
                size="small"
                onClick={getAIExif}
                startIcon={<AutoAwesome />}
              >
                AI EXIF
              </LoadingButton>
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 1, marginTop: '10px' }}>
            <Grid container>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Author Name"
                  name="Author"
                  placeholder="Name of the author"
                  onChange={handleChange}
                  value={site.settings.exif?.Author}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Copyright"
                  name="Copyright"
                  placeholder=""
                  onChange={handleChange}
                  value={site.settings.exif?.Copyright}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Website"
                  name="Website"
                  placeholder='eg. "abc.com'
                  onChange={handleChange}
                  value={site.settings.exif?.Website}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Software"
                  name="Software"
                  placeholder='eg. "Adobe Photoshop CC"'
                  onChange={handleChange}
                  value={site.settings.exif?.Software}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Camera Brand"
                  name="CameraBrand"
                  placeholder='e.g. "Canon"'
                  onChange={handleChange}
                  value={site.settings.exif?.CameraBrand}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Camera Model"
                  name="CameraModel"
                  placeholder='e.g. "Canon EOS 5D Mark IV"'
                  onChange={handleChange}
                  value={site.settings.exif?.CameraModel}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Exposure Time"
                  name="ExposureTime"
                  placeholder='e.g. "1/1000"'
                  onChange={handleChange}
                  value={site.settings.exif?.ExposureTime}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="F-Number"
                  name="FNumber"
                  placeholder='e.g. "f/2.8"'
                  onChange={handleChange}
                  value={site.settings.exif?.FNumber}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="ISO Speed Ratings"
                  name="ISOSpeedRatings"
                  placeholder='e.g. "100"'
                  onChange={handleChange}
                  value={site.settings.exif?.ISOSpeedRatings}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Focal Length"
                  name="FocalLength"
                  placeholder='e.g. "50mm"'
                  onChange={handleChange}
                  value={site.settings.exif?.FocalLength}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Lens Model"
                  name="LensModel"
                  placeholder='e.g. "EF 24-70mm f/2.8L II USM"'
                  onChange={handleChange}
                  value={site.settings.exif?.LensModel}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  select
                  label="Flash"
                  name="Flash"
                  onChange={handleChange}
                  value={site.settings.exif?.Flash}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Exposure Mode"
                  name="ExposureMode"
                  placeholder='e.g. "Manual"'
                  onChange={handleChange}
                  value={site.settings.exif?.ExposureMode}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="GPS Information"
                  name="GPSinformation"
                  placeholder="e.g. 12.345678, 98.765432"
                  onChange={handleChange}
                  value={site.settings.exif?.GPSinformation}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>

      <Dialog open={openExifChooser} onClose={() => setOpenExifChooser(false)}>
        <DialogTitle>Choose EXIF data</DialogTitle>

        <DialogContent>
          {exifData?.length === 0 && (
            <Typography variant="body1" color="text.secondary" maxWidth="300px">
              You don't have any saved EXIF data. Add some EXIF layout from the
              <Link
                to="/exifs"
                style={{
                  textDecoration: 'none',
                  color: '#1976d2',
                  fontWeight: 500
                }}
              >
                {' '}
                My EXIF{' '}
              </Link>
              page.
            </Typography>
          )}
          {exifData?.map((exif, index) => (
            <Box
              key={index}
              border="1px solid #ccc"
              borderRadius="5px"
              padding="10px"
              marginBottom="10px"
              minWidth="300px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                updateSite({ settings: { exif: { ...exif } } });
                setOpenExifChooser(false);
              }}
            >
              <Typography variant="body1">{exif.name}</Typography>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenExifChooser(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EXIF;
