import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  styled,
  Button,
  Grid,
  Icon,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";

import { H2 } from "app/components/Typography";

import Loading from "app/components/MatxLoading";
import { useState } from "react";
import { useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import useAuth from "app/hooks/useAuth";
import useSettings from "app/hooks/useSettings";
import { Link, useNavigate } from "react-router-dom";
import UpgradeDialog from "../UpgradePlanDialog";
import ClosableInfo from "app/components/ClosableInfo";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
  position: "relative",
}));

export default function GenerationHistory() {
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [planMsg, setPlanMsg] = useState("");
  const { replaceGenPanel, settings, replaceAllPrompts } = useSettings();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (settings.prompts) return;

    const getPrompts = async () => {
      const ref = collection(
        getFirestore(),
        "userData",
        getAuth().currentUser.uid,
        "prompts"
      );

      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      replaceAllPrompts(nPrompts);
    };

    getPrompts();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      let lastPossibleDate = Date.now()+86400000*3;

      for (let i = 0; i < 5; i++, lastPossibleDate-=86400000) {
        let d = new Date(lastPossibleDate).getDate();
        const rf = ref(
          getDatabase(),
          `/${d}/${getAuth().currentUser.uid}`
        );
        const snapshot = await get(rf);

        if (snapshot.exists()) {
          let data = snapshot.val();

          //iterate over the data map
          data = Object.keys(data).map((key) => {
            return {
              ...data[key],
              trackID: key + "-" + d,
            };
          });

          data.sort((a, b) => {
            return b.time - a.time;
          });

          setData((prev) => [...prev, ...data]);
        }
      }
    };

    fetchData()
      .then(() => {
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  }, []);

  console.log(data);

  return (
    <div>
      <Snackbar
        open={!!errorMsg}
        autoHideDuration={3000}
        onClose={() => setErrorMsg(null)}
        sx={{ height: "15%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert sx={{ m: 1 }} severity={"error"} variant="filled">
          {errorMsg}
        </Alert>
      </Snackbar>

      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />
      <FlexBox style={{ marginTop: "25px" }}>
        <H2 style={{ position: "absolute", top: "-15px", left: "12px" }}>
          History
        </H2>
      </FlexBox>

      <FlexBox>
        <Box
          sx={{ padding: "10px 20px" }}
          md={{ padding: "15px 25px" }}
          style={{ maxWidth: "1500px" }}
        >
          <TableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="25%">
                    <b>Date</b>
                  </TableCell>
                  <TableCell align="center" width="15%">
                    <b>Status</b>
                  </TableCell>
                  <TableCell align="center" width="15%%">
                    <b>Prompt</b>
                  </TableCell>
                  <TableCell align="center" width="30%">
                    <b>Images</b>
                  </TableCell>
                  <TableCell align="center" width="15%">
                    <b>Track</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!isFetching && data.length == 0 && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={5}>
                      <div
                        style={{
                          width: "100%",
                          height: "50px",
                          marginTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        <h3>No image generation history was found</h3>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {data.map((item, index) => (
                  <TableRow key={item.trackID + index}>
                    <TableCell>
                      {new Date(item.time).toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      <label
                        style={{
                          color: "white",
                          backgroundColor:
                            item.statusCode === 200
                              ? "green"
                              : item.statusCode === 420
                              ? "orange"
                              : "red",
                          fontWeight: "bold",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                      >
                        {item.statusCode === 200
                          ? "done"
                          : item.statusCode === 420
                          ? "processing"
                          : "error"}
                      </label>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        disabled={!item.promptID || !settings.prompts}
                        onClick={() => {
                          let prompt, index;
                          for (let i = 0; i < settings.prompts.length; i++) {
                            if (settings.prompts[i].id == item.promptID) {
                              prompt = settings.prompts[i];
                              index = i;
                              break;
                            }
                          }

                          if (!prompt) {
                            setErrorMsg("Prompt not found");
                            return;
                          }

                          if (index >= user.plan.saas.save_prompts) {
                            setPlanMsg(
                              `This prompt was disabled as you have reached the limit of ${user.plan.saas.save_prompts} saved prompts. `
                            );
                            return;
                          }

                          replaceGenPanel(prompt);
                          navigate("/prompt_generator");
                        }}
                      >
                        view
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      {item.statusCode === 200 ? (
                        <Grid container spacing={1}>
                          {Object.keys(item.images).map((key, index) => (
                            <Grid item xs={12} md={6} lg={3} key={index}>
                              <Link to={item.images[key]} target="_blank">
                                <img
                                  width={64}
                                  src={item.images[key]}
                                  loading="lazy"
                                />
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <p>Not available</p>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {item.statusCode === 200 || item.statusCode == 420 ? (
                        <Link to={`/track/${item.trackID}`} target="_blank">
                          <Icon>arrow_right_alt</Icon>
                        </Link>
                      ) : (
                        "..."
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                {isFetching && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={5}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <Stack width="100%" justifyContent="center" alignItems="center">
            <ClosableInfo
              name={"generation_history"}
              msg={
                "History of the generated images will only be available for 2 days. After that, the images along with the history will be deleted."
              }
            />
          </Stack>
        </Box>
      </FlexBox>
    </div>
  );
}
