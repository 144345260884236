import CircleIcon from '@mui/icons-material/Circle';
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loading from 'app/components/MatxLoading';
import useSettings from 'app/hooks/useSettings';

// - Image width: Set the maximum width in pixels for horizontal images.
// - Image height: Set the maximum height in pixels for vertical images.
// - Number of Images: The cover image is always generated, regardless of the selected option.

const setupInstructions = [
  <>
    <b>Image Preset:</b> Bring a unique style to your articles with customizable presets. Create and
    adjust them in the Prompt Generator to match your visual identity.
  </>,
  <>
    <b>Image width: </b> Set the maximum width in pixels for horizontal images.
  </>,

  <>
    <b>Image height: </b> Set the maximum height in pixels for vertical images.
  </>,

  <>
    <b>Number of Images: </b> The cover image is always generated, regardless of the selected
    option.
  </>
];

const ImagesSettings = ({ site, updateSite }) => {
  const { settings } = useSettings();
  if (!site) {
    return (
      <Stack m={5}>
        <Loading />
      </Stack>
    );
  }

  return (
    <Card>
      <Typography variant="h6" sx={{ color: '#1976d2', padding: '12px 16px', fontWeight: 500 }}>
        General
      </Typography>
      <Divider />
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Stack gap="1.4rem">
                <FormLabel>Cover Image Configuration</FormLabel>
                <TextField
                  select
                  defaultValue=""
                  value={site.settings.cover_preset_id || ''}
                  onChange={(e) => {
                    updateSite({ settings: { cover_preset_id: e.target.value } });
                  }}
                  variant="outlined"
                  label="Cover Image Preset"
                  fullWidth
                >
                  <MenuItem value="">Select a preset for cover image</MenuItem>
                  {settings?.presets &&
                    settings.presets.map((preset) => (
                      <MenuItem key={preset.id} value={preset.id}>
                        {preset.name}
                      </MenuItem>
                    ))}
                </TextField>

                <Stack direction="row" gap="1rem">
                  <TextField
                    value={site.settings?.cover_image_size?.[0]}
                    onChange={(e) => {
                      updateSite({
                        settings: {
                          cover_image_size: [
                            parseInt(e.target.value),
                            site.settings?.cover_image_size?.[1] || 1024
                          ]
                        }
                      });
                    }}
                    variant="outlined"
                    label="Cover Image Width"
                    fullWidth
                    type="number"
                  />

                  <TextField
                    value={site.settings.cover_image_size?.[1]}
                    onChange={(e) =>
                      updateSite({
                        settings: {
                          cover_image_size: [
                            site.settings?.cover_image_size?.[0] || 1024,
                            parseInt(e.target.value)
                          ]
                        }
                      })
                    }
                    variant="outlined"
                    label="Cover Image Height"
                    fullWidth
                    type="number"
                  />
                </Stack>

                <TextField
                  select
                  value={site.settings.cover_image_aspect_ratio}
                  onChange={(e) => {
                    updateSite({ settings: { cover_image_aspect_ratio: e.target.value } });
                  }}
                  variant="outlined"
                  label="Cover Image Aspect Ratio"
                  fullWidth
                  style={{ width: '50%' }}
                >
                  <MenuItem value="--ar 1:1">1:1</MenuItem>
                  <ListSubheader>Horizontal</ListSubheader>
                  <MenuItem value="--ar 3:2">3:2</MenuItem>
                  <MenuItem value="--ar 16:9">16:9</MenuItem>
                  <MenuItem value="--ar 5:4">5:4</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>

                  <ListSubheader>Vertical</ListSubheader>
                  <MenuItem value="--ar 2:3">2:3</MenuItem>
                  <MenuItem value="--ar 9:16">9:16</MenuItem>
                  <MenuItem value="--ar 4:5">4:5</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>
                </TextField>

                <FormControlLabel
                  label="remove all images before article processing"
                  control={
                    <Checkbox
                      checked={site.settings.remove_images}
                      onChange={(e) => {
                        updateSite({ settings: { remove_images: e.target.checked } });
                      }}
                    />
                  }
                />
                <Stack>
                  <FormLabel id="radio-buttons-group-label">Number of Images to Generate</FormLabel>
                  <RadioGroup
                    value={site.settings.h2_per_image}
                    onChange={(e) => {
                      updateSite({ settings: { h2_per_image: parseInt(e.target.value) } });
                    }}
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Only Generate the cover photo"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Generate 1 image for every 2 H2 tags"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Generate 1 image for every 3 H2 tags"
                    />
                  </RadioGroup>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack gap="1.4rem">
                <FormLabel>Article Image Configuration</FormLabel>
                <TextField
                  select
                  defaultValue=""
                  value={site.settings.article_preset_id}
                  onChange={(e) => updateSite({ settings: { article_preset_id: e.target.value } })}
                  variant="outlined"
                  label="Article Image Preset"
                  fullWidth
                >
                  <MenuItem value="">Select a preset for article image</MenuItem>
                  {settings?.presets &&
                    settings.presets.map((preset) => (
                      <MenuItem key={preset.id} value={preset.id}>
                        {preset.name}
                      </MenuItem>
                    ))}
                </TextField>

                <Stack direction="row" gap="1rem">
                  <TextField
                    value={site.settings.image_size[0]}
                    onChange={(e) =>
                      updateSite({
                        settings: {
                          image_size: [parseInt(e.target.value), site.settings.image_size[1]]
                        }
                      })
                    }
                    variant="outlined"
                    label="Article Image Width"
                    fullWidth
                    type="number"
                  />

                  <TextField
                    value={site.settings.image_size[1]}
                    onChange={(e) =>
                      updateSite({
                        settings: {
                          image_size: [site.settings.image_size[0], parseInt(e.target.value)]
                        }
                      })
                    }
                    variant="outlined"
                    label="Article Image Height"
                    fullWidth
                    type="number"
                  />
                </Stack>

                <TextField
                  select
                  value={site.settings.aspect_ratio}
                  onChange={(e) => updateSite({ settings: { aspect_ratio: e.target.value } })}
                  variant="outlined"
                  label="Article Image Aspect Ratio"
                  fullWidth
                  style={{ width: '50%' }}
                >
                  <MenuItem value="--ar 1:1">1:1</MenuItem>
                  <ListSubheader>Horizontal</ListSubheader>
                  <MenuItem value="--ar 3:2">3:2</MenuItem>
                  <MenuItem value="--ar 16:9">16:9</MenuItem>
                  <MenuItem value="--ar 5:4">5:4</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>

                  <ListSubheader>Vertical</ListSubheader>
                  <MenuItem value="--ar 2:3">2:3</MenuItem>
                  <MenuItem value="--ar 9:16">9:16</MenuItem>
                  <MenuItem value="--ar 4:5">4:5</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>
                </TextField>

                <Stack>
                  <Typography variant="subtitle1">Image Configuration</Typography>
                  <Typography variant="body2">
                    To optimize the display of your images and customize their appearance, configure
                    the following settings:
                  </Typography>
                  <ul style={{ paddingLeft: '0' }}>
                    {setupInstructions.map((instruction, index) => (
                      <li
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'start'
                        }}
                      >
                        <CircleIcon
                          sx={{
                            color: '#1976d2',
                            fontSize: '8px',
                            marginRight: '6px',
                            marginTop: '6px'
                          }}
                        />

                        <span>{instruction}</span>
                      </li>
                    ))}
                  </ul>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ImagesSettings;
