import {
  AccordionDetails,
  AccordionSummary,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Icon,
  Input,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  useTheme
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import VerifyEmail from '../VerifyEmail';
import useSettings from 'app/hooks/useSettings';
import { useEffect } from 'react';
import { useState } from 'react';

import { Box, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import './style.css';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import shortid from 'shortid';
import { ExpandMore } from '@mui/icons-material';
import PanelPreview from './PreviewDialog';
import { db } from '../../../firebase';
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore';
import UpgradeDialog from '../UpgradePlanDialog';
import { Link, useNavigate } from 'react-router-dom';

const ExpansionPanel = styled(Accordion)(() => ({
  '&.root': {
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, .125)',
    '&:not(:last-child)': { borderBottom: 0 },
    '&:before': { display: 'none' },
    '&$expanded': { margin: 'auto' }
  }
}));

const ExpansionPanelSummary = styled(AccordionSummary)({
  '&.root': {
    minHeight: 56,
    marginBottom: -1,
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    '&$expanded': { minHeight: 56 }
  },
  '& .content': { '&$expanded': { margin: '12px 0' } }
});

const ExpansionPanelDetails = styled(AccordionDetails)(({ theme }) => ({
  '&.root': { padding: theme.spacing(2) }
}));

const DullInput = (props) => {
  const [focused, setFocused] = useState(false);
  return (
    <input
      className="dull-input"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
      style={{
        border: 'none',
        fontWeight: props.w,
        fontSize: props.sz
      }}
    ></input>
  );
};

const InputField = ({ panel, setPanel, index }) => {
  const [field, setField] = useState(panel.fields[index]);

  useEffect(() => {
    let newPanel = { ...panel };
    newPanel.fields[index] = field;
    setPanel(newPanel);
  }, [field]);

  const removeField = () => {
    let newPanel = { ...panel };
    newPanel.fields.splice(index, 1);
    setPanel(newPanel);
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Box display={'flex'} alignItems={'center'} width={'100%'}>
          <Button style={{ float: 'right', color: 'red' }} onClick={removeField}>
            <Icon>remove_circle_sharp</Icon>
          </Button>

          <DullInput
            value={field.name}
            placeHolder={'Enter field label'}
            w={'bold'}
            onChange={(e) => {
              let newField = { ...field };
              newField.name = e.target.value;
              setField(newField);
            }}
          />
        </Box>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 3, md: 5 }}
          justifyContent={{ xs: 'center' }}
        >
          <Grid item xs={11} md={5}>
            <Box
              display={'flex'}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={2}
            >
              <label>Placeholder text</label>
              <input
                width={'50%'}
                value={field.placeholder}
                onChange={(e) => {
                  setField({ ...field, placeholder: e.target.value });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={11} md={5}>
            <Box
              display={'flex'}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={2}
            >
              <label s tyle={{ width: '200px' }}>
                Field type
              </label>
              <select>
                <option value="text">Text</option>
                <option value="number">Number</option>
              </select>
            </Box>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const OptionHandler = ({ index, field, setField }) => {
  const [data, setData] = useState(field.options[index]);

  useEffect(() => {
    let mf = { ...field };
    mf.options[index] = data;
    setField(mf);
  }, [data]);

  return (
    <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} gap={4}>
      <TextField
        placeholder="Enter name"
        value={data.text}
        onChange={(e) => setData({ ...data, text: e.target.value })}
      />

      <TextField
        placeholder="Enter Value"
        value={data.value}
        onChange={(e) => setData({ ...data, value: e.target.value })}
      />

      <Button
        onClick={() => {
          let mf = { ...field };
          mf.options.splice(index, 1);
          setField(mf);
        }}
      >
        <Icon style={{ color: 'red' }}>remove</Icon>
      </Button>
    </Box>
  );
};

const getValuesCount = (fields) => {
  let count = 0;
  fields.forEach((f) => {
    if (f.options) count += f.options.length - 1;
  });

  return count + fields.length - 1;
};

const OptionGroupHandler = ({ index, field, setField, canAdd }) => {
  const [data, setData] = useState(field.options[index]);
  const { user } = useAuth();

  useEffect(() => {
    let mf = { ...field };
    mf.options[index] = data;
    setField(mf);
  }, [data]);

  return (
    <Box marginTop={'8px'} style={{ border: '1px solid #D8D8D8', padding: '5px' }}>
      <Box display={'flex'} width={'100%'}>
        <DullInput
          value={data.name}
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
        />

        <Grid container justifyContent={'flex-end'}>
          <Button
            onClick={() => {
              if (!canAdd()) return;
              let nd = { ...data };
              nd.options.push({ text: 'Name', value: 'value', rKey: shortid.generate() });
              setData(nd);
            }}
          >
            <Icon>add</Icon>
          </Button>

          <Button
            onClick={() => {
              let mf = { ...field };
              mf.options.splice(index, 1);
              setField(mf);
            }}
          >
            <Icon style={{ color: 'red' }}>delete</Icon>
          </Button>
        </Grid>
      </Box>

      {data.options.map((f, i) => {
        return <OptionHandler key={f.rKey} index={i} field={data} setField={setData} />;
      })}
    </Box>
  );
};

const SelectField = ({ panel, setPanel, index, setPlanMsg }) => {
  const [field, setField] = useState(panel.fields[index]);
  const { user } = useAuth();

  useEffect(() => {
    let newPanel = { ...panel };
    newPanel.fields[index] = field;
    setPanel(newPanel);
  }, [field]);

  const removeField = () => {
    let newPanel = { ...panel };
    newPanel.fields.splice(index, 1);
    setPanel(newPanel);
  };

  const canAdd = () => {
    const cnt = getValuesCount(panel.fields[index].options);
    if (cnt >= user.plan.saas.prompt_setting.value) {
      setPlanMsg('You have reached the limit of options for your current plan');
      return false;
    }

    return true;
  };

  const addOption = () => {
    if (!canAdd()) return;

    let nf = { ...field };
    nf.options.push({ text: 'Name', value: 'Value', rKey: shortid.generate() });

    setField(nf);
  };

  const addOptionGroup = () => {
    if (!canAdd()) return;

    let nf = { ...field };
    nf.options.push({
      name: 'Group name',
      options: [{ text: 'Name', value: 'Value', rKey: shortid.generate() }],
      rKey: shortid.generate()
    });
    setField(nf);
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Box display={'flex'} alignItems={'center'} width={'100%'}>
          <Button style={{ float: 'right', color: 'red' }} onClick={removeField}>
            <Icon>remove_circle_sharp</Icon>
          </Button>

          <DullInput
            value={field.name}
            placeHolder={'Enter field label'}
            w={'bold'}
            onChange={(e) => {
              let newField = { ...field };
              newField.name = e.target.value;
              newField.options[0].text = '--Select ' + newField.name + '--';
              setField(newField);
            }}
          />
        </Box>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={1}
        >
          {field.options.map((f, index) => {
            if (index == 0) return null;

            if (f.name)
              return (
                <OptionGroupHandler
                  key={f.rKey}
                  index={index}
                  field={field}
                  setField={setField}
                  canAdd={canAdd}
                />
              );

            return <OptionHandler key={f.rKey} index={index} field={field} setField={setField} />;
          })}

          <Box
            display={'flex'}
            width={'100%'}
            justifyContent={'right'}
            alignItems={'right'}
            gap={4}
          >
            <Button startIcon={<Icon>add</Icon>} onClick={addOption}>
              New Option
            </Button>

            <Button startIcon={<Icon>add</Icon>} onClick={addOptionGroup}>
              New Option group
            </Button>
          </Box>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const EditPanel = () => {
  const { user } = useAuth();
  const { replacePanelCreator, replaceAllPanels, settings } = useSettings();
  const [panel, setPanel] = useState(settings.panelCreator);
  const [isSaving, setIsSaving] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [planMsg, setPlanMsg] = useState(null);
  const [defPanelCnt, setDefPanelCnt] = useState(null);
  const navigator = useNavigate();

  useEffect(() => {
    let not_custom = 0;
    if (settings.panels) {
      for (let i = 0; i < settings.panels.length; i++)
        not_custom += settings.panels[i].custom ? 1 : 0;
      setDefPanelCnt(settings.panels.length - not_custom);
    }
  }, []);

  useEffect(() => {
    replacePanelCreator({ ...panel });
  }, [panel]);

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  if (!panel) {
    navigator('/prompt_settings');
    return null;
  }

  const savePanel = async () => {
    setIsSaving(true);

    let newPanel = { ...panel };

    if (newPanel.id) {
      const ref = doc(db, 'userData', user.uid, 'panels', newPanel.id);
      await updateDoc(ref, newPanel);
    } else {
      if (
        settings.panels &&
        settings.panels.length - defPanelCnt >= user.plan.saas.prompt_setting.tabs
      ) {
        setIsSaving(false);

        setPlanMsg('You can only add ' + user.plan.saas.panels + ' tabs in your current plan.');
        return;
      }

      const ref = await addDoc(collection(db, 'userData', user.uid, 'panels'), newPanel);
      newPanel.id = ref.id;
      setPanel(newPanel);
    }

    if (settings.panels) {
      let panels = [...settings.panels];
      let index = panels.findIndex((p) => p.id == newPanel.id);
      if (index == -1) {
        panels.push(newPanel);
      } else {
        panels[index] = newPanel;
      }

      replaceAllPanels(panels);
    } else {
      replaceAllPanels([newPanel]);
    }
    setIsSaving(false);
  };

  const addDropDown = () => {
    if (
      !user.plan.saas.prompt_setting.enable ||
      panel.fields.length >= user.plan.saas.prompt_setting.fields
    ) {
      setPlanMsg(
        'You can only add ' + user.plan.saas.prompt_setting.fields + ' fields in your current plan.'
      );

      return;
    }

    let newPanel = { ...panel };

    newPanel.fields.unshift({
      name: 'Dropdown',
      icon: '',
      id: 'custom-' + shortid.generate(),
      rKey: shortid.generate(),
      options: [
        {
          text: '--Select option--',
          value: '$'
        }
      ],
      tag: 'select'
    });
    setPanel(null);
    setPanel(newPanel);
  };

  const addInputField = () => {
    if (
      !user.plan.saas.prompt_setting.enable ||
      panel.fields.length >= user.plan.saas.prompt_setting.fields
    ) {
      setPlanMsg(
        'You can only add ' + user.plan.saas.prompt_setting.fields + ' fields in your current plan.'
      );

      return;
    }

    let newPanel = { ...panel };
    newPanel.fields.unshift({
      name: 'Input Field',
      icon: '',
      id: 'custom-' + shortid.generate(),
      tag: 'input',
      type: 'text',
      placeholder: 'placeholder',
      rKey: shortid.generate()
    });

    setPanel(newPanel);
  };

  return (
    <div>
      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />
      <PanelPreview setOpen={setShowPreview} op={showPreview} panel={panel} />
      <Box m={{ xs: 1, md: 2 }} display={'flex'}>
        <Box maxWidth={'50%'}>
          <DullInput
            w={'bold'}
            sz={'25px'}
            value={panel.name}
            placeHolder={'Enter panel name'}
            onChange={(e) => {
              setPanel({ ...panel, name: e.target.value });
            }}
          />
        </Box>

        <Grid container justifyContent={{ xs: 'flex-end' }} spacing={{ xs: 1, md: 3 }}>
          <Grid item>
            <Button variant="outlined" onClick={() => setShowPreview(true)}>
              Preview
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton loading={isSaving} variant="contained" onClick={savePanel}>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>

      <Box m={{ xs: 2, md: 1 }} display={'flex'} justifyContent={'center'} gap={1}>
        <Button onClick={addDropDown} startIcon={<Icon>add_circle </Icon>}>
          New Dropdown
        </Button>

        <Button onClick={addInputField} startIcon={<Icon>add_circle </Icon>}>
          New Input
        </Button>
      </Box>

      <Box m={{ xs: 1, md: 2 }}>
        {panel.fields.map((field, index) => {
          if (field.tag === 'input')
            return <InputField key={field.rKey} panel={panel} setPanel={setPanel} index={index} />;
          else
            return (
              <SelectField
                setPlanMsg={setPlanMsg}
                key={field.rKey}
                panel={panel}
                setPanel={setPanel}
                index={index}
              />
            );
        })}
      </Box>
    </div>
  );
};

export default EditPanel;
