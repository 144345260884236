import React from 'react';

import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  Radio,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate } from 'react-router-dom';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { useEffect, useState } from 'react';

import { H3 } from 'app/components/Typography';

import Loading from 'app/components/MatxLoading';

import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

const CredentialChooserDialog = (props) => {
  const { user } = useAuth();
  const [creds, setCreds] = useState([]);
  const [selectedCred, setSelectedCred] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showChooseCred, setShowChooseCred] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getCredentials = async () => {
      const dc = await getDoc(doc(getFirestore(), 'userData', user.uid));
      setCreds(dc.data().dc_creds);
      setSelectedCred(dc.data().selected_dc_cred);
    };
    getCredentials();
  }, []);

  const setSelected = async (index) => {
    setIsSaving(true);

    await updateDoc(doc(getFirestore(), 'userData', user.uid), {
      selected_dc_cred: index
    });
    setIsSaving(false);
    setSelectedCred(index);
    props.proceed();
  };

  return (
    <Dialog open={props.open}>
      <DialogContent>
        <div
          style={{
            minWidth: '350px'
          }}
        >
          <Grid container columnSpacing={2} rowSpacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <H3>Select Generation Credentials</H3>
              </div>
              <br></br>
              {selectedCred !== null && (
                <div>
                  <Box>
                    <Radio
                      disabled={isSaving}
                      checked={selectedCred == -1}
                      onChange={(e) => {
                        setSelected(-1);
                      }}
                    />
                    Use Administrative Credential
                  </Box>

                  {creds.map((cred, index) => (
                    <Box key={cred.dc_token + cred.channel_id}>
                      <Radio
                        disabled={isSaving}
                        checked={selectedCred == index}
                        onChange={(e) => {
                          setSelected(index);
                        }}
                      />

                      <div style={{ display: 'inline-block', marginLeft: '10px', minWidth: '60%' }}>
                        {cred.name}
                      </div>
                    </Box>
                  ))}
                </div>
              )}

              {selectedCred === null && <Loading />}
            </Grid>
          </Grid>
          <br></br>
          <br></br>
          <Checkbox
            inputProps={{
              'aria-label': 'Show Credential choosing dialog each time before generating image'
            }}
            checked={showChooseCred}
            onChange={(e) => {
              setShowChooseCred(e.target.checked);
              window.localStorage.setItem('showChooseCred', e.target.checked ? 'false' : 'true');
            }}
          />
          Do not show this again
          <p
            style={{
              color: 'grey',
              fontSize: '12px'
            }}
          >
            You can change this setting in the{' '}
            <Link
              onClick={() => {
                navigate('/credentials');
              }}
              to="/credentials"
            >
              API & Credentials
            </Link>{' '}
            page
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSaving}
          onClick={() => {
            props.cancel();
          }}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          disabled={isSaving}
          onClick={() => {
            props.proceed();
          }}
          variant="contained"
        >
          Skip & Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CredentialChooserDialog;
