import {
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Stack,
  styled,
  Tooltip,
} from "@mui/material";
import { Small } from "app/components/Typography";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "24px !important",
  paddingBottom: "5px !important",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  "& small": { color: theme.palette.text.secondary },
  "& .icon": {
    opacity: 0.6,
    fontSize: "44px",
    color: theme.palette.primary.main,
  },
}));

const Heading = styled("h6")(({ theme }) => ({
  margin: 0,
  marginTop: "4px",
  fontSize: "14px",
  fontWeight: "500",
  cursor: "pointer",
  color: theme.palette.primary.main,
}));

const cardList = [
  {
    name: "Smart Generation",
    amount:
      "Type in a topic or paste a paragraph, and let Alphonse suggest some ideas",
    icon: "auto_awesome",
    link: "/magic_generator",
  },
  {
    name: "History",
    amount: "View the latest generations over the last 48 hours.",
    icon: "history",
    link: "/history",
  },
  {
    name: "My Prompts",
    amount: "Access your prompt quickly",
    icon: "assignment",
    link: "/saved",
  },
  {
    name: "Wordpress Post",
    amount: "Quickly generate visuals to illustrate your content.",
    icon: "add_photo_alternate",
    link: "/add_image_to_post",
  },
  {
    name: "Wordpress History",
    amount: "Manage the latest visuals generated for your wordpress site.",
    icon: "history_edu",
    link: "/wordpress_history",
  },
  {
    name: "Prompt Generator",
    amount: "Create your prompts and generate visuals like a pro.",
    icon: "camera_enhance",
    link: "/prompt_generator",
  },
];
const StatCards = () => {
  const navigate = useNavigate();

  return (
    <Grid container spacing={3} sx={{ mb: "24px" }}>
      {cardList.map((item, index) => (
        <Grid item xs={12} md={6} key={index}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Icon className="icon">{item.icon}</Icon>
              <Box ml="10px">
                <Small>{item.name}</Small>
                <Heading onClick={() => navigate(item.link)}>
                  {item.amount}
                </Heading>
              </Box>
            </ContentBox>
            <Stack
              direction="row"
              spacing={1}
              alignItems="right"
              width="100%"
              justifyContent="flex-end"
            >
              <Tooltip title="View Details" placement="top">
                <IconButton onClick={() => navigate(item.link)}>
                  <Icon>arrow_right_alt</Icon>
                </IconButton>
              </Tooltip>
            </Stack>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatCards;
