import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function UpgradeDialog({ msg, setMsg }) {
  const navigate = useNavigate();

  return (
    <Box>
      <Dialog
        open={msg}
        onClose={() => setMsg(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Upgrade</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg}

            <h4>Do you want to upgrade your account?</h4>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setMsg(null)} color="primary" variant="outlined">
            cancel
          </Button>

          <Button
            onClick={() => {
              navigate('/pricing');
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
