import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  Checkbox
} from '@mui/material';
import { useState } from 'react';
import AutomaticImageTable from './AutometicImageTable';
import Loading from 'app/components/MatxLoading';
import dayjs from 'dayjs';

const frequencyData = [
  {
    id: 24,
    title: 'Every 24 hours'
  },
  {
    id: 12,
    title: 'Every 12 hours'
  },
  {
    id: 6,
    title: 'Every 6 hours'
  },
  {
    id: 3,
    title: 'Every 3 hours'
  }
];

const AutometicImage = ({ site, updateSite }) => {
  console.log('site', site);
  if (!site) {
    return (
      <Stack marginTop="12px">
        <Loading />
      </Stack>
    );
  }

  return (
    <Card>
      <Typography variant="h6" sx={{ color: '#1976d2', padding: '12px 16px', fontWeight: 500 }}>
        Scheduled Generation
      </Typography>
      <Divider />
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5} justifyContent="space-between">
            <Grid item xs={12} md={7}>
              <Stack gap="1.4rem">
                <FormControlLabel
                  control={
                    <Switch
                      checked={site.active}
                      onChange={(e) => updateSite({ active: e.target.checked })}
                      sx={{ marginRight: '4px' }}
                    />
                  }
                  label="Automatically generate images for my articles"
                />

                <Box>
                  <Typography variant="subtitle1">Category Configuration</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    You can choose preset for your article and cover images for individual category.
                    Also you can turn off a category to skip automatic processing articles that are
                    in that category. Use the refresh button to update the list of categories.
                  </Typography>

                  <AutomaticImageTable site={site} updateSite={updateSite} />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack gap="1.8rem">
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={site.settings.activate_exif}
                        onChange={(e) =>
                          updateSite({ settings: { activate_exif: e.target.checked } })
                        }
                        sx={{ marginRight: '4px' }}
                      />
                    }
                    label="Use AI generated EXIF for each image"
                  />
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    This will add EXIF data to each image generated by the AI. Fields like Author,
                    Copyright, Website will be taken from this site's EXIF saved by you.
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="subtitle1">Post Type</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Select the post types you want to automatically generate images for. You can
                    choose multiple post types.
                  </Typography>

                  <Select
                    multiple
                    value={site?.autoPostType || ['future']}
                    onChange={(e) => {
                      console.log('e.target.value', e.target.value);
                      updateSite((p) => ({
                        ...p,
                        autoPostType:
                          typeof e.target.value === 'string'
                            ? e.target.value.split(',')
                            : e.target.value
                      }));
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: '16px' }}
                  >
                    <MenuItem value="publish">Published Posts</MenuItem>
                    <MenuItem value="draft">Draft Posts</MenuItem>
                    <MenuItem value="private">Private Posts</MenuItem>
                    <MenuItem value="pending">Pending Posts</MenuItem>
                    <MenuItem value="future">Future Posts</MenuItem>
                  </Select>
                </Box>

                <Box>
                  <Typography variant="subtitle1">Processing Frequency</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Select how often you want to process your articles for automatic image
                    generation.
                  </Typography>

                  <Select
                    value={site?.autoFrequency || 24}
                    onChange={(e) => {
                      updateSite({ autoFrequency: parseInt(e.target.value) });
                    }}
                    variant="outlined"
                    label="Frequency (CORN)"
                    fullWidth
                    sx={{ marginTop: '16px' }}
                  >
                    {frequencyData.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <Typography variant="subtitle1">Next Trigger</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    The next time your articles will be processed for automatic image generation.
                    You can modify this time to delay the processing.
                  </Typography>

                  <TextField
                    type="datetime-local"
                    value={dayjs(site?.nextTrigger).format('YYYY-MM-DDTHH:mm')}
                    onChange={(e) =>
                      updateSite({ nextTrigger: dayjs(e.target.value).toDate().getTime() })
                    }
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: '16px' }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AutometicImage;
