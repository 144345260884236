import { CancelOutlined, CheckCircle, Download } from '@mui/icons-material';
import {
  Alert,
  Button,
  CircularProgress,
  css,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect } from 'react';
import Files from 'react-files';
import { toast } from 'react-toastify';
import './stylesheet.css';

const statuses = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  Queued: 'Queued'
};
const BatchSiteAddRow = ({ url, userName, password, status, setStatus }) => {
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState('');

  const addSite = async () => {
    setLoading(true);
    setErr('');

    try {
      let fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const res = await fn({
        command: 'addSite',
        url,
        userName,
        password
      });

      setStatus(statuses.SUCCESS);
    } catch (e) {
      if (e.code === 'functions/invalid-argument') {
        setErr('Please enter valid information');
      } else if (e.code === 'functions/permission-denied') {
        setErr('Invalid username or password');
      }
      setStatus(statuses.ERROR);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (status === statuses.LOADING) {
      addSite();
    }
  }, [status]);

  return (
    <TableRow>
      <TableCell width="80%">{url}</TableCell>
      <TableCell align="right" width="20%">
        {status === statuses.Queued && 'Queued'}
        {status === statuses.LOADING && <CircularProgress size={20} />}
        {status === statuses.SUCCESS && <CheckCircle color="success" />}
        {status === statuses.ERROR && <CancelOutlined color="error" />}
      </TableCell>
    </TableRow>
  );
};

const STARTING_COUNT = 6;
const parseSitesFromCSV = async (file) => {
  let csv = await file.text();
  let sites = csv.split('\n');
  sites.shift();
  sites = sites.filter((site) => site.trim() !== '');

  sites = sites.map((site) => {
    let [url, userName, password] = site.split(',').map((s) => s.trim());
    return { url, userName, password, status: statuses.Queued };
  });

  //remove duplicate urls
  let newSites = [];
  let siteSet = new Set();

  sites.forEach((site) => {
    if (!siteSet.has(site.url)) {
      siteSet.add(site.url);
      newSites.push(site);
    }
  });

  for (let i = 0; i < newSites.length && i < STARTING_COUNT; i++) {
    newSites[i].status = statuses.LOADING;
  }
  return newSites;
};

const BatchSiteAdd = ({ open, setOpen }) => {
  const [sitesToAdd, setSitesToAdd] = React.useState([]);
  const [cancelled, setCancelled] = React.useState(false);
  const [isCancelling, setIsCancelling] = React.useState(false);
  const [successCount, setSuccessCount] = React.useState(0);

  const tryCancelling = async () => {
    setIsCancelling(true);
    setCancelled(true);

    while (sitesToAdd.some((site) => site.status === statuses.LOADING)) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    setIsCancelling(false);
    setOpen(false);
  };

  const downloadErrored = () => {
    let csv = 'URL,Username,Password,Status\n';
    sitesToAdd.forEach((site) => {
      if (site.status === statuses.ERROR) {
        csv += `${site.url},${site.userName},${site.password},${site.status}\n`;
      }
    });

    let blob = new Blob([csv], { type: 'text/csv' });
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'errored_sites.csv';
    a.click();
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        {sitesToAdd.length === 0
          ? 'Add Multiple Sites'
          : `Adding Sites ${successCount}/${sitesToAdd.length}`}
      </DialogTitle>

      <DialogContent>
        {sitesToAdd.length === 0 && (
          <Stack minWidth="500px" minHeight="300px" spacing={2}>
            <Typography variant="body1" textAlign={'center'}>
              To add multiple sites at once, upload a CSV file with the following format
            </Typography>
            <img
              style={{
                height: 'auto',
                margin: '0 40px'
              }}
              src="/assets/images/csv-example.png"
            />
            <Alert severity="info">
              If you have cloudflare or other security measures enabled for a site, please first
              whitelist our IP address
              <strong
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigator.clipboard.writeText('35.193.8.109');
                  toast.success('IP Copied');
                }}
              >
                {' '}
                35.193.8.109
              </strong>
            </Alert>
            <Files
              className="dropzonearea"
              onChange={async (files) => {
                if (files.length === 0) return;
                let file = files[0];

                let sites = await parseSitesFromCSV(file);
                setSitesToAdd(sites);
              }}
              onError={() => {
                toast.error('Error parsing file');
              }}
              accepts={['text/csv', '.csv']}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              Drop file here or click to upload
            </Files>
          </Stack>
        )}
        {sitesToAdd.length > 0 && (
          <Stack>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">URL</TableCell>
                  <TableCell align="right" width="20%">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              {sitesToAdd.map((site) => (
                <BatchSiteAddRow
                  key={site.url}
                  url={site.url}
                  userName={site.userName}
                  password={site.password}
                  status={site.status}
                  setStatus={(status) => {
                    setSitesToAdd((sites) =>
                      sites.map((s) => {
                        if (s.url === site.url) {
                          if (status === statuses.SUCCESS) {
                            setSuccessCount((count) => count + 1);
                          }
                          return { ...s, status };
                        }
                        return s;
                      })
                    );

                    //set another site to loading
                    let nextSite = sitesToAdd.find((s) => s.status === statuses.Queued);
                    if (nextSite && !cancelled) {
                      setSitesToAdd((sites) =>
                        sites.map((s) => {
                          if (s.url === nextSite.url) {
                            return { ...s, status: statuses.LOADING };
                          }
                          return s;
                        })
                      );
                    }
                  }}
                />
              ))}
            </Table>
          </Stack>
        )}
      </DialogContent>
      {sitesToAdd.length > 0 && (
        <DialogActions>
          <Button onClick={downloadErrored} startIcon={<Download />} color="error">
            download errored
          </Button>
          <Button onClick={tryCancelling} disabled={isCancelling} color="primary">
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default BatchSiteAdd;
