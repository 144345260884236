import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import AlphonseAPI from './views/AlphonseAPI/AlphonseAPI';
import Credential from './views/credential';
import Dashboard from './views/dashboard/Dashboard';
import EditPanel from './views/editPanel';
import GenerationHistory from './views/generation_history/GenerationHistory';
import Generator from './views/generator';
import MagicGenerator from './views/magic_generator/MagicGenerator';
import MagicGeneratorMain from './views/magic_generator/MagicGeneratorMain';
import Pricing from './views/plans';
import Invoices from './views/plans/Invoices';
import PaymentCancel from './views/plans/PaymentCancel';
import PaymentSuccess from './views/plans/PaymentSuccess';
import SavedPresets from './views/presets';
import AccountProfile from './views/profile';
import PromptSetting from './views/prompt_setting';
import SavedPrompts from './views/saved';
import Settings from './views/settings';
import SmartGenerator from './views/smart_generator/SmartGenerator';
import Tracker from './views/tracker';
import WordpressHistory from './views/wordpress/history';
import AddImageToPost from './views/wordpress/singlePostGen';

import ManageExif from './views/exif';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: '/exifs',
        element: <ManageExif />,
        auth: authRoles.admin
      },
      {
        path: '/saved',
        element: <SavedPrompts />,
        auth: authRoles.admin
      },
      {
        path: '/presets',
        element: <SavedPresets />,
        auth: authRoles.admin
      },

      {
        path: '/prompt_settings',
        element: <PromptSetting />,
        auth: authRoles.admin
      },

      {
        path: '/settings',
        element: <Settings />,
        auth: authRoles.admin
      },

      {
        path: '/editPanel',
        element: <EditPanel />,
        auth: authRoles.admin
      },

      {
        path: '/account',
        element: <AccountProfile />,
        auth: authRoles.admin
      },

      {
        path: '/pricing',
        element: <Pricing />,
        auth: authRoles.admin
      },

      {
        path: '/history',
        element: <GenerationHistory />,
        auth: authRoles.admin
      },

      {
        path: '/wordpress_sites/:siteID',
        element: <Settings />,
        auth: authRoles.admin
      },
      {
        path: '/add_image_to_post',
        element: <AddImageToPost />,
        auth: authRoles.admin
      },

      {
        path: '/wordpress_history',
        element: <WordpressHistory />,
        auth: authRoles.admin
      },
      {
        path: '/credentials',
        element: <Credential />,
        auth: authRoles.admin
      },
      {
        path: '/alphonse_api',
        element: <AlphonseAPI />,
        auth: authRoles.admin
      },

      {
        path: '/prompt_generator',
        element: <Generator />,
        auth: authRoles.admin
      },

      {
        path: '/magic_generator',
        element: <SmartGenerator />,
        auth: authRoles.admin
      },

      {
        path: '/add_image_in_post',
        element: <MagicGenerator />,
        auth: authRoles.admin
      },

      {
        path: '/add_image_in_post/:siteID/:postID',
        element: <MagicGeneratorMain />,
        auth: authRoles.admin
      },

      {
        path: '/dashboard',
        element: <Dashboard />,
        auth: authRoles.admin
      },

      {
        path: '/track/:trackID',
        element: <Tracker />,
        auth: authRoles.admin
      },
      {
        path: '/payment/invoices',
        element: <Invoices />,
        auth: authRoles.admin
      }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },

  // payment confirmation pages
  { path: '/payment/success', element: <PaymentSuccess /> },
  { path: '/payment/cancel', element: <PaymentCancel /> },

  { path: '/', element: <Navigate to="/dashboard" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
