import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import { Box, Grid, Icon, ListSubheader, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import Loading from 'app/components/MatxLoading';
import useSettings from 'app/hooks/useSettings';
import useAuth from 'app/hooks/useAuth';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function InputTabs({ values, setValues, data }) {
  const [panels, setPanels] = useState(data || []);
  const { replaceAllPanels } = useSettings();

  const { user } = useAuth();

  useEffect(() => {
    if (data) return;
    //load panels from firestore

    async function getPanels() {
      const getAll = async (ref) => {
        const snapshot = await getDocs(ref);
        const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        return data;
      };

      const defaults = await getAll(collection(db, 'defaultPanels'));
      const userPanels = await getAll(collection(db, 'userData', user.uid, 'panels'));

      const allPanels = [...defaults, ...userPanels];
      setPanels(allPanels);
      replaceAllPanels(allPanels);
    }

    getPanels();
  }, []);

  return (
    <>
      {panels.length === 0 && <Loading />}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={values.active}
          onChange={(e, newValue) => setValues({ ...values, active: newValue })}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          {panels.map((item, index) => (
            <Tab
              {...{ active: values.active === index ? true : false }}
              value={index}
              key={item.rKey}
              label={item.name}
              icon={item.icon ? <Icon>{item.icon}</Icon> : null}
            ></Tab>
          ))}
        </Tabs>
      </Box>

      {panels.map((item, index) => (
        // TabPanel
        <TabPanel value={values.active} index={index} key={item.rKey}>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* grid of  <select></select> or <textField></textField>  */}
            {item.fields.map((field, index) => (
              // Grid item
              <Grid item xs={12} md={4} lg={3} key={field.rKey}>
                <label htmlFor={field.id}>{field.name}</label>
                {/* mapping an individual field */}

                {field.tag === 'select' && (
                  <Select
                    style={{ width: '100%' }}
                    id={field.id}
                    value={values.data[field.id] ? values.data[field.id] : '$'}
                    placeholder="Select"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        data: { ...values.data, [field.id]: e.target.value }
                      });
                    }}
                  >
                    {field.options.map((option, index) => {
                      if (option.text)
                        return [
                          <MenuItem key={option.rKey} value={option.value}>
                            {option.text}
                          </MenuItem>
                        ];
                      else {
                        return [
                          <ListSubheader key={option.rKey + 'head'}>{option.name}</ListSubheader>,
                          option.options.map((option, index) => (
                            <MenuItem key={option.rKey} value={option.value}>
                              {option.text}
                            </MenuItem>
                          ))
                        ];
                      }
                    })}
                  </Select>
                )}
                {field.tag === 'input' && (
                  <TextField
                    key={field.rKey}
                    id={field.id}
                    variant="outlined"
                    style={{ width: '100%' }}
                    value={values.data[field.id] ? values.data[field.id] : ''}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        data: { ...values.data, [field.id]: e.target.value }
                      });
                    }}
                    type={field.type}
                    placeholder={field.placeholder}
                  ></TextField>
                )}
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      ))}
    </>
  );
}
