import { Typography } from '@mui/material';

const ComingSoon = () => {
  return (
    <div className="container">
      <Typography variant="h6" sx={{ color: '#1976d2', padding: '12px 16px', fontWeight: 500 }}>
        Coming Soon
      </Typography>
      <div
        style={{
          marginTop: '25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          position: 'relative',
          padding: '20px 0px'
        }}
      >
        <img
          src="/assets/images/illustrations/announcement.svg"
          alt="Coming Soon"
          className="img-fluid"
        />
        <h4 className="mt-4">We are currently working on this feature</h4>
        <div className="text-muted">
          Automate sharing posts with vibrant images to the social platforms. Will be live soon.
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
