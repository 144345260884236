import { LoadingButton } from '@mui/lab';
import { CardContent, Select, MenuItem } from '@mui/material';
import app from '../../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { he } from 'date-fns/locale';

export default function PricingCard({ price, isMonthly }) {
  const { from, to, angle } = price.style;
  const [isLoading, setIsLoading] = useState(false);
  const [qty, setQty] = useState(1);
  let priceGbp = price[isMonthly ? 'monthly' : 'yearly'];

  //format price to always have 2 decimal places
  priceGbp = priceGbp.toFixed(2);

  const getLink = async () => {
    const fnc = getFunctions(app);
    const getCheckoutLink = httpsCallable(fnc, 'getChekoutLink');
    setIsLoading(true);

    try {
      const { data } = await getCheckoutLink({ name: price.name, qty, isMonthly });

      if (data) {
        //opne checkout link in new tab
        window.location.href = data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <div
        style={{
          width: '250px',
          height: '100%',
          border: '1px solid #e0e0e0',
          borderRadius: '10px',
          padding: '20px',
          textAlign: 'center',
          maxWidth: '350px',
          boxShadow: '0.25em 0.25em 2em rgba(0, 0, 0, 0.25)',
          backgroundImage: `linear-gradient(${angle}deg, ${from}, ${to})`
        }}
      >
        <div
          style={{
            height: '85%'
          }}
        >
          <span
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              padding: '5px 20px',
              borderRadius: '0 10px 10px 0',
              background: 'white',
              color: from,
              boxShadow: '0.25em 0.25em 2em rgba(0, 0, 0, 0.25)',
              marginLeft: '-128px'
            }}
          >
            {price.name}
          </span>

          <div>
            <h1>
              €{priceGbp}
              <label style={{ fontSize: '12px' }}> {isMonthly ? '/month' : '/year'}</label>
            </h1>
          </div>

          <CardContent>
            <div>
              {price.features.map((feature, index) => (
                <p key={index}>{feature}</p>
              ))}
            </div>
          </CardContent>
        </div>
        <div style={{}}>
          <div>
            <label>Buy for </label>
            <Select
              disableUnderline={true}
              variant="standard"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 }
              }}
            >
              {[...Array(12)].map((_, i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
            <label>
              {' '}
              {isMonthly ? 'month' : 'year'}
              {'(s)'}{' '}
            </label>
          </div>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            fullWidth
            onClick={getLink}
            variant="contained"
            sx={{
              marginTop: '5px'
            }}
          >
            Buy Now
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
