import { Link } from "react-router-dom";

const { Stack, Alert } = require("@mui/material");

const WordpressLimitCrossed = ({ user }) => {
  if (!user || !user.acc || !user.wp_usage || !user.plan) return null;

  if (user.acc.wp_usage.count >= user.plan.wp_rest_api.max_post_per_day)
    return (
      <Stack
        width={"100%"}
        direction="row"
        alignItems="center"
        style={{ margin: "10px 0" }}
      >
        <Alert style={{ width: "100%" }} severity="warning">
          You have reached the limit of {user.plan.wp_rest_api.max_post_per_day}{" "}
          posts per day. No more posts will be further processed today. Please{" "}
          <Link to="/pricing">
            <strong> upgrade </strong>{" "}
          </Link>{" "}
          your plan to continue.
        </Alert>
      </Stack>
    );

  return null;
};

export default WordpressLimitCrossed;
