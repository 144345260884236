import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

export default function AlertDialog({ op, setResult, name }) {
  return (
    <Box>
      <Dialog
        open={op}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {' '}
            Prompt <b>"{name}"</b> will be deleted.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setResult(false)} color="primary" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={() => setResult(true)}
            style={{ backgroundColor: 'red', color: 'white' }}
            autoFocus
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
