import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import { MatxLoading } from "app/components";
import { auth, db } from "../../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import _ from "lodash";
import useSettings from "app/hooks/useSettings";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case "LOGOUT": {
      return { ...initialState, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    case "UPDATE_API_KEY": {
      const { user } = action.payload;
      return { ...state, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
  updateAPIKey: () => {},
});

async function get_data(uid) {
  let ref = doc(db, "userAccount", uid || auth.currentUser.uid);
  let acc = await getDoc(ref);
  acc = acc.data();
  if (!acc) {
    //the firebase function is not fast enough to create the user account
    //delay for the database to be updated
    await new Promise((resolve) => setTimeout(resolve, 2000));
    acc = await getDoc(ref);
    acc = acc.data();
  }
  ref = doc(db, "plans", acc.plan);
  const accPlan = await getDoc(ref);

  return { plan: accPlan.data(), acc: acc };
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { clearSettings } = useSettings();
  

  const login = async (email, password, remember) => {
    if (remember) {
      await setPersistence(auth, browserLocalPersistence);
    } else {
      await setPersistence(auth, browserSessionPersistence);
    }

    await signInWithEmailAndPassword(auth, email, password);

    if (auth.currentUser) {
      const d = await get_data();
      dispatch({
        type: "LOGIN",
        payload: { user: { ...auth.currentUser, ...d } },
      });
    }
  };

  const register = async (email, username, password) => {
    await createUserWithEmailAndPassword(auth, email, password);

    if (auth.currentUser) {
      if (username) {
        await updateProfile(auth.currentUser, {
          displayName: username,
        });
      }

      //delay for the database to be updated
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const d = await get_data(auth.currentUser.uid);
      dispatch({
        type: "REGISTER",
        payload: { user: { ...auth.currentUser, ...d } },
      });
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    clearSettings();
    signOut(auth);
  };

  const updateAPIKey = (key) => {
    const user_cpy = _.merge(state.user, { acc: { api_key: key } });
    dispatch({ type: "UPDATE_API_KEY", payload: { user: { ...user_cpy } } });
  };

  useEffect(() => {
    const ret = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const d = await get_data();
          dispatch({
            type: "INIT",
            payload: { isAuthenticated: true, user: { ...user, ...d } },
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    });

    // (async () => {
    //   console.log('auth.currentUser', auth.currentUser);
    //   if (auth.currentUser) {
    //
    //   } else {
    //     dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
    //   }
    // })();

    return ret;
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register, updateAPIKey }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
