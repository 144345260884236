import {
  Box,
  Button,
  Fab,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { use } from 'echarts';
import { useEffect, useState } from 'react';
import { MatxSearchBox } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { blue, red } from '@mui/material/colors';
import { H2 } from 'app/components/Typography';
import AlertDialog from './DeleteDialog';
import { LoadingButton } from '@mui/lab';
import { db } from '../../../firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot } from 'firebase/firestore';
import { cloneDeep, replace } from 'lodash';
import Loading from 'app/components/MatxLoading';
import { useTheme } from '@emotion/react';
import shortid from 'shortid';
import { addKeysToPanel } from '../generator/datamodel';
import UpgradeDialog from '../UpgradePlanDialog';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}));

const ButtonPanel = ({ item, deleteItem, forkItem, index, delLoading, forkLoading, disabled }) => {
  const navigate = useNavigate();
  const { replacePanelCreator } = useSettings();
  const { user } = useAuth();

  return (
    <div style={{ position: 'relative' }}>
      <LoadingButton
        disabled={!item.custom || !user.plan.saas.prompt_setting.enable || disabled}
        loading={delLoading}
        onClick={() =>
          deleteItem((i) => {
            if (i == -1) return index;

            return i;
          })
        }
      >
        {!delLoading && <Icon>delete</Icon>}
      </LoadingButton>

      <Button
        disabled={!item.custom || !user.plan.saas.prompt_setting.enable || disabled}
        onClick={() => {
          if (!item.custom || !user.plan.saas.prompt_setting.enable) return;

          replacePanelCreator({ ...item });
          navigate('/editPanel');
        }}
      >
        <Icon>edit</Icon>
      </Button>

      <LoadingButton
        disabled={!user.plan.saas.prompt_setting.enable || disabled}
        loading={forkLoading}
        onClick={() => {
          forkItem((item) => {
            if (item == -1) {
              return index;
            }
            return item;
          });
        }}
      >
        <Icon>fork_right</Icon>
      </LoadingButton>
    </div>
  );
};

const PromptSetting = () => {
  const { settings, replaceAllPanels } = useSettings();
  const [data, setData] = useState(settings.panels);
  const [itemToDelete, setItemToDelete] = useState(-1);
  const [itemToForkm, setItemToFork] = useState(-1);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [defPanelCnt, setDefPanelCnt] = useState(null);
  const matches = useMediaQuery('(min-width:600px)');
  const { replacePanelCreator } = useSettings();
  const [isEmptyList, setIsEmptyList] = useState(-1);
  const navigate = useNavigate();
  const theme = useTheme();
  const [planMsg, setPlanMsg] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    if (itemToDelete >= 0) setDeleteOpen(true);
  }, [itemToDelete]);

  useEffect(() => {
    let not_custom = 0;
    if (data) {
      for (let i = 0; i < data.length; i++) not_custom += data[i].custom ? 1 : 0;
      setDefPanelCnt(data.length - not_custom);
    }
  }, [data]);

  useEffect(() => {
    const work = async () => {
      if (itemToForkm != -1) {
        if (!canDuplicate(itemToForkm)) {
          setItemToFork(-1);
          return;
        }

        let newo = cloneDeep(data[itemToForkm]);
        newo.custom = true;
        //rename the id of the fields
        newo.fields = newo.fields.map((field) => {
          field.id = 'custom-' + shortid.generate();
          return field;
        });

        addKeysToPanel(newo);

        const ret = await addDoc(collection(db, 'userData', user.uid, 'panels'), newo);
        newo.id = ret.id;

        setData([...data, newo]);
        replaceAllPanels([...data, newo]);

        setItemToFork(-1);
      }
    };
    work();
  }, [itemToForkm]);

  useEffect(() => {
    if (data) return;
    //load panels from firestore

    async function getPanels() {
      const getAll = async (ref) => {
        const snapshot = await getDocs(ref);
        const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        return data;
      };

      const defaults = await getAll(collection(db, 'defaultPanels'));

      const userPanels = await getAll(collection(db, 'userData', user.uid, 'panels'));

      const allPanels = [...defaults, ...userPanels];
      setData(allPanels);
      replaceAllPanels(allPanels);
    }

    getPanels();
  }, []);

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  const canDuplicate = (index) => {
    console.log('canDuplicate', data, index, defPanelCnt, user.plan.saas.prompt_setting.tabs);
    if (
      !user.plan.saas.prompt_setting.enable ||
      data.length - defPanelCnt >= user.plan.saas.prompt_setting.tabs
    ) {
      setPlanMsg('You can have only ' + user.plan.saas.prompt_setting.tabs + ' custom tabs.');
      return false;
    }

    const fieldsl = data[index].fields.length;

    if (fieldsl > user.plan.saas.prompt_setting) {
      setPlanMsg(
        'Could not make a copy of the tab. This tab has ' +
          fieldsl +
          ' fields. You can have only ' +
          user.plan.saas.prompt_setting.fields +
          ' fields per tab.'
      );
      return false;
    }

    let max_values = 0;

    const getValuesCount = (fields) => {
      let count = 0;
      fields.forEach((f) => {
        if (f.options) count += f.options.length - 1;
      });

      return count + fields.length - 1;
    };

    data[index].fields.forEach((field) => {
      if (field.options) max_values = Math.max(max_values, getValuesCount(field.options));
    });

    if (max_values > user.plan.saas.prompt_setting.value) {
      setPlanMsg(
        'Could not make a copy of the tab. This tab has maximum ' +
          max_values +
          ' values per field. You can have only ' +
          user.plan.saas.prompt_setting.value +
          ' values per field.'
      );
      return false;
    }

    return true;
  };

  const deleteItem = async (proceed) => {
    setDeleteOpen(false);
    if (!proceed) {
      setItemToDelete(-1);
      return;
    }

    if (itemToDelete < 0) return;
    const ref = doc(db, 'userData', user.uid, 'panels', data[itemToDelete].id);
    await deleteDoc(ref);

    const newData = [...data];
    newData.splice(itemToDelete, 1);
    setData(newData);
    replaceAllPanels(newData);

    setItemToDelete(-1);
  };

  return (
    <div>
      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />
      <AlertDialog
        op={deleteOpen}
        setResult={deleteItem}
        name={itemToDelete < 0 ? '' : data[itemToDelete].name}
      ></AlertDialog>
      <FlexBox style={{ marginTop: '25px', marginBottom: '25px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>My Panels</H2>
      </FlexBox>
      <FlexBox>
        <Box
          sx={{ padding: '10px 20px' }}
          md={{ padding: '15px 25px' }}
          style={{ maxWidth: '600px' }}
        >
          <TableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width={!matches ? '40%' : '30%'}>
                    <b>Name</b>
                  </TableCell>
                  <TableCell align="right" width={!matches ? '60%' : '70%'}>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!data && (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}
                {data &&
                  data.map((item, index) => {
                    if (!item.custom && !user.plan.saas.prompt_setting.show_defaults) {
                      return null;
                    }

                    return (
                      <TableRow key={item.rKey}>
                        <TableCell>
                          {item.name}
                          <Icon>{item.icon}</Icon>
                        </TableCell>
                        <TableCell align="right">
                          <ButtonPanel
                            disabled={
                              !user.plan.saas.prompt_setting.enable ||
                              index - defPanelCnt >= user.plan.saas.prompt_setting.tabs
                            }
                            item={item}
                            deleteItem={setItemToDelete}
                            forkItem={setItemToFork}
                            index={index}
                            delLoading={itemToDelete == index}
                            forkLoading={itemToForkm == index}
                          ></ButtonPanel>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {data && data.length <= 8 && !user.plan.saas.prompt_setting.show_defaults ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No custom tabs found.
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </FlexBox>

      <div style={{ position: 'fixed', right: '30px', bottom: '50px', zIndex: 99 }}>
        <Fab
          disabled={!(user.plan.saas.prompt_setting.enable && defPanelCnt != null)}
          style={{
            background: theme.palette.secondary.main
          }}
          onClick={() => {
            if (
              !user.plan.saas.prompt_setting.enable ||
              data.length - defPanelCnt >= user.plan.saas.prompt_setting.tabs
            ) {
              setPlanMsg(
                'You can have only ' + user.plan.saas.prompt_setting.tabs + ' custom tabs.'
              );
              return;
            }
            replacePanelCreator({
              name: 'Custome Panel',
              icon: '',
              custom: true,
              fields: []
            });

            navigate('/editPanel');
          }}
        >
          <Icon>add</Icon>
        </Fab>
      </div>
    </div>
  );
};

export default PromptSetting;
