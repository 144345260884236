import React from 'react';

import {
  Box,
  Button,
  Icon,
  InputAdornment,
  Link,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import { useEffect, useState } from 'react';
import { H2, H3 } from 'app/components/Typography';
import { LoadingButton } from '@mui/lab';
import Loading from 'app/components/MatxLoading';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { CardDiv, FlexBox } from '../credential';

const AlphonseAPI = () => {
  const [userData, setUserData] = useState(null);
  const { user, updateAPIKey } = useAuth();
  const [key_show, setKeyShow] = useState('');
  const [allowedDomains, setAllowedDomains] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      const dc = await getDoc(doc(getFirestore(), 'userData', user.uid));
      let data = dc.data();
      setUserData(data);
      setAllowedDomains(data?.allowed_domains || []);
    };
    getUserData();
  }, []);

  useEffect(() => {
    let key = user.acc.api_key;
    key = key.substring(0, 5);
    for (let i = 5; i < user.acc.api_key.length - 4; i++) {
      key += '*';
    }
    setKeyShow(key + user.acc.api_key.substring(user.acc.api_key.length - 4));
    console.log(key);
  }, [user]);

  const deleteDomain = async (index) => {
    setIsSaving(true);

    const fn = httpsCallable(getFunctions(), 'manageUserData');
    await fn({ command: 'removeDomain', toRemove: allowedDomains[index] });

    setIsSaving(false);
  };

  const regenerateKey = async () => {
    setIsRegenerating(true);
    const fn = httpsCallable(getFunctions(), 'regenerateAPIKey');
    const resp = await fn();

    updateAPIKey(resp.data.new_key);
    setIsRegenerating(false);
  };

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <FlexBox style={{ marginTop: '25px', marginBottom: '10px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>AlphonseAPI</H2>
      </FlexBox>

      <FlexBox>
        <Box m={{ xs: 2, sm: 4, md: 5 }} width={{ sm: '70%' }}>
          <CardDiv>
            <H3>API key</H3>
            <p>
              Your API key is used to authenticate your requests to the API. You can view your API
              key below. You should keep this key secret, and only store it on your own servers.
              Your API key carries many privileges, so be sure to keep it secret!
            </p>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    md: '70%',
                    lg: '60%'
                  }
                }}
              >
                <TextField
                  disabled
                  value={key_show}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          endIcon={<Icon>content_copy</Icon>}
                          onClick={() => {
                            navigator.clipboard.writeText(user.acc.api_key);
                          }}
                        ></Button>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </div>
            <p>
              If you believe your API key has been compromised, you can regenerate a new API key.
              Regenerating your API key will invalidate the old key.
            </p>
            <LoadingButton
              loading={isRegenerating}
              startIcon={<Icon>refresh</Icon>}
              onClick={regenerateKey}
            >
              Regenerate API key
            </LoadingButton>

            <br></br>
            <br></br>
            <H3>Allowed Domains</H3>
            <p>
              Your API key can only be used from domains that you whitelist. To whitelist a domain,
              enter its full URL below. Any subdomains of the whitelisted domain will also be
              allowed.
            </p>
            <p>
              To add a domain, use the <code>/checkValidity</code> API endpoint from the domain you
              wish to whitelist. View the full API documentation{' '}
              <Link
                href="https://app.swaggerhub.com/apis/faridulreza/Alphonse/1.0.0#/default/get_checkValidity"
                target="_blank"
              >
                here
              </Link>
              .
            </p>

            <br></br>
            <br></br>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center'
              }}
            >
              <Table
                sx={{
                  width: {
                    xs: '100%',
                    md: '70%',
                    lg: '60%'
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={'80%'}>Domain</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {allowedDomains &&
                  allowedDomains.map((domain, index) => (
                    <TableRow>
                      <TableCell width={'80%'}>{domain.domain + ' (' + domain.ip + ')'}</TableCell>

                      <TableCell align="center">
                        <Button disabled={isSaving} onClick={() => deleteDomain(index)}>
                          <Icon>delete</Icon>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                {!allowedDomains && (
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}
                {allowedDomains && allowedDomains.length == 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      No domains added
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </div>
          </CardDiv>
        </Box>
      </FlexBox>
    </div>
  );
};

export default AlphonseAPI;
