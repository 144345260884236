import styled from '@emotion/styled';
import { CancelOutlined, CheckCircle, SettingsApplications } from '@mui/icons-material';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const { useEffect, useState } = require('react');

const Pbox = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
`;

const LinkText = styled.label`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;
const ShowWordpressOnBoarding = ({ userData }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (userData) {
      if (
        !userData?.openai_keys ||
        userData?.openai_keys.length === 0 ||
        !userData?.dc_creds ||
        userData?.dc_creds.length === 0
      ) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [userData]);

  return (
    <Dialog onClose={() => true} open={open}>
      <DialogTitle>Almost There!</DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          Before you start, make sure you have the following settings configured. click on the
          action items to setup the incomplete settings.
        </Typography>
        <DialogContentText>
          <Pbox>
            {!userData?.openai_keys || userData?.openai_keys.length === 0 ? (
              <CancelOutlined color="error" />
            ) : (
              <CheckCircle color="success" />
            )}
            <Link to="/credentials">
              <LinkText>Add</LinkText> at least one OpenAI key
            </Link>
          </Pbox>
          <Pbox>
            {!userData?.dc_creds || userData?.dc_creds.length === 0 ? (
              <CancelOutlined color="error" />
            ) : (
              <CheckCircle color="success" />
            )}
            <Link to="/credentials">
              <LinkText>Add</LinkText> at least one discord credential for image generation
            </Link>
          </Pbox>
          <Pbox>
            <SettingsApplications />
            <Link to="/wordpress_sites/default">
              <LinkText>Check</LinkText> your default wordpress settings. These settings will be
              applied to all the sites you add by default
            </Link>
          </Pbox>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ShowWordpressOnBoarding;
