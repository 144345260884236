const { CloseOutlined } = require("@mui/icons-material");
const { IconButton, Stack } = require("@mui/material");
const { useEffect, useState } = require("react");

const ClosableInfo = ({ msg, name }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(name) !== "was_closed") {
      setOpen(true);
    }
  }, [name]);

  if (!open) return null;

  return (
    <Stack
    width={"100%"}
      direction="row"

      alignItems="center"
      style={{ backgroundColor: "lightblue", margin: "10px 0"}}
    >
      <IconButton
        onClick={() => {
          setOpen(false);
          localStorage.setItem(name, "was_closed");
        }}
      >
        <CloseOutlined />
      </IconButton>

      <p style={{
        textAlign: "center",
        paddingRight: "10px",
      }}>{msg}</p>
    </Stack>
  );
};

export default ClosableInfo;
