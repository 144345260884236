import { Alert, Button, Icon, Typography } from '@mui/material';
import { pad } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';

export default function PaymentSuccess() {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          padding: '50px',
          textAlign: 'center',
          flexDirection: 'column'
        }}
      >
        <Icon
          style={{
            fontSize: '35px',
            color: 'green'
          }}
        >
          task_alt
        </Icon>
        <h1>Payment Successfull</h1>
        <div>
          Your payment was successfull. Once we verify your payment, your plan will be activated.
          You should see a new invoice <u onClick={() => navigate('/payment/invoices')}>here</u>{' '}
          once the payment is verified.
        </div>
        <br></br>

        <Button variant="contained" onClick={() => navigate('/prompt_generator')}>
          Home
        </Button>
      </div>
    </div>
  );
}
