import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  Stack,
  Box,
  Button
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import useSettings from 'app/hooks/useSettings';
import { LoadingButton } from '@mui/lab';
import { Refresh, Today } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect } from 'react';

const AutomaticImageTable = ({ site, updateSite }) => {
  const { settings } = useSettings();
  const [tableData, setTableData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [allActive, setAllActive] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (site?.categories) {
      let filtered = site.categories.filter((item) =>
        item.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
      );
      setTableData(filtered);
    }
  }, [site, searchQuery]);

  const handleToggleStatus = (id) => {
    updateSite((p) => ({
      ...p,
      categories: p.categories.map((category) =>
        category.id === id
          ? { ...category, status: category.status === 'active' ? 'inactive' : 'active' }
          : category
      )
    }));
  };

  const handlePresetChange = (presetType, id, newPreset) => {
    updateSite((p) => ({
      ...p,
      categories: p.categories.map((category) =>
        category.id === id ? { ...category, [presetType]: newPreset } : category
      )
    }));
  };

  const handleToggleAll = () => {
    const newStatus = allActive ? 'inactive' : 'active';

    updateSite((p) => ({
      ...p,
      categories: p.categories.map((category) => ({ ...category, status: newStatus }))
    }));

    setAllActive(!allActive);
  };

  const refreshCategories = async () => {
    setRefreshing(true);
    try {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const res = await fn({
        command: 'getCategories',
        ...site
      });

      let categories = res?.data || [];
      let prevCategories = site?.categories || [];

      let merged = categories.map((category) => {
        let prevCategory = prevCategories.find((prev) => prev.id === category.id);
        return {
          ...category,
          status: prevCategory?.status || 'active',
          article_preset: prevCategory?.article_preset || '',
          cover_preset: prevCategory?.cover_preset || ''
        };
      });

      updateSite((p) => ({ ...p, categories: merged }));
    } catch (e) {
      console.log(e);
      toast.error('Failed to fetch categories');
    }

    setRefreshing(false);
  };

  return (
    <Box marginTop="24px">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom="14px"
      >
        <TextField
          label="Search by Category"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: '55%' }}
        />

        <LoadingButton
          color="primary"
          size="small"
          onClick={refreshCategories}
          loading={refreshing}
          loadingPosition="start"
          startIcon={<Refresh />}
        >
          refresh
        </LoadingButton>
        <FormControlLabel
          label={allActive ? 'Disable all' : 'Enable all'}
          labelPlacement="start"
          control={<Switch checked={allActive} onChange={handleToggleAll} />}
          sx={{ justifyContent: 'flex-end' }}
        />
      </Stack>

      <TableContainer component={Paper} style={{ maxHeight: '400px', minHeight: '350px' }}>
        <Table aria-label="simple table">
          <TableHead sx={{ border: 'none' }}>
            <TableRow>
              <TableCell align="left" sx={{ pl: 2 }}>
                Category
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Article Preset</TableCell>
              <TableCell align="center">Cover Preset</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((item, index) => (
                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" align="left" sx={{ pl: 2 }} scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={item.status}
                      onClick={() => handleToggleStatus(item.id)}
                      size="small"
                      sx={{
                        width: '64px',
                        height: '22px',
                        backgroundColor: item.status === 'active' ? '#1976d2' : '#f44336',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: item.status === 'active' ? '#1976d2' : '#f44336'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      select
                      value={item.article_preset}
                      onChange={(e) =>
                        handlePresetChange('article_preset', item.id, e.target.value)
                      }
                      variant="standard"
                      size="small"
                      sx={{ minWidth: 100 }}
                      InputProps={{
                        disableUnderline: true
                      }}
                    >
                      {settings.presets.map((preset) => (
                        <MenuItem key={preset.id} value={preset.id}>
                          {preset.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell align="center">
                    <TextField
                      select
                      value={item.cover_preset}
                      onChange={(e) => handlePresetChange('cover_preset', item.id, e.target.value)}
                      variant="standard"
                      size="small"
                      sx={{ minWidth: 100 }}
                      InputProps={{
                        disableUnderline: true
                      }}
                    >
                      {settings.presets.map((preset) => (
                        <MenuItem key={preset.id} value={preset.id}>
                          {preset.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AutomaticImageTable;
