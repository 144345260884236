import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputTabs from '../generator/inputTabs';
import { styled, useMediaQuery } from '@mui/material';
import { themes } from 'app/components/MatxTheme/initThemes';
import { useTheme } from '@emotion/react';

const DialogRoot = styled('div')(({ theme }) => ({
  '& form': {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    width: 'fit-content'
  },
  '& .formControl': {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  '& .formControlLabel': {
    marginTop: theme.spacing(1)
  }
}));

export default function PreviewDialog({ op, panel, setOpen }) {
  const [maxWidth, setMaxWidth] = useState('md');
  const [tabData, setTabData] = useState({ data: {}, active: 0 });
  const theme = useTheme();

  function handleClose() {
    setOpen(false);
  }

  return (
    <DialogRoot>
      <Dialog
        open={op}
        fullWidth={true}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            <InputTabs values={tabData} data={[panel]} setValues={setTabData} />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </DialogRoot>
  );
}
