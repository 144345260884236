import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  Snackbar,
  Alert
} from '@mui/material';
import { auth } from '../../../firebase';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import Loading from 'app/components/MatxLoading';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import DeleteDialog from './DeleteDialog';
import useAuth from 'app/hooks/useAuth';
import { Link } from 'react-router-dom';

const veryfyPassword = async (oldPassword) => {
  const credential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword);
  await reauthenticateWithCredential(auth.currentUser, credential);
};

const PasswordChanger = () => {
  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [isMatch, setIsMatch] = useState(true);
  const [isLoading, setIsLoading] = useState(-1);

  useEffect(() => {
    if (values.newPassword !== values.confirmPassword) {
      setIsMatch(false);
    } else {
      setIsMatch(true);
    }
  }, [values]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(0);
    veryfyPassword(values.oldPassword)
      .then(() => {
        if (values.newPassword === values.confirmPassword) {
          updatePassword(auth.currentUser, values.newPassword)
            .then(() => {
              setIsLoading(3);
            })
            .catch((error) => {
              setIsLoading(1);
            });
        } else {
          setIsLoading(1);
        }
      })
      .catch((error) => {
        setIsLoading(2);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading > 0 && (
        <Box m={2}>
          <Alert severity={isLoading < 3 ? 'error' : 'success'}>
            {isLoading == 1 && 'Could not change password'}
            {isLoading == 2 && 'Wrong password'}
            {isLoading == 3 && 'Password changed successfully'}
          </Alert>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Old Password"
            name="oldPassword"
            onChange={handleChange}
            type="password"
            value={values.oldPassword}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="New Password"
            name="newPassword"
            onChange={handleChange}
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Confirm Password"
            name="confirmPassword"
            onChange={handleChange}
            error={!isMatch}
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <LoadingButton
          disabled={!isMatch}
          loading={isLoading === 0}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Change Password
        </LoadingButton>
      </Box>
    </form>
  );
};

export const AccountProfileDetails = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const { user } = useAuth();
  console.log(user);
  return (
    <Card xs={{ width: '100%' }}>
      <CardHeader title="Account" />
      <CardContent>
        <Box width={'100%'}>
          <DeleteDialog op={openDelete} setOp={setOpenDelete} />
          <section>
            <h4>Plan</h4>
            <div>
              <span
                style={{
                  padding: '0.2rem 0.5rem',
                  borderRadius: '0.3rem',
                  background: '#e0e0e0',
                  color: '#000',
                  marginRight: '0.4rem'
                }}
              >
                {user.plan.name}
              </span>
              {!!user.acc.expiryTime && (
                <>
                  valid till{' '}
                  <i>
                    {new Date(user.acc.expiryTime).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </i>
                </>
              )}
            </div>
          </section>
          <section>
            <h4>Payment</h4>
            <u>
              <Link to="/payment/invoices/">
                <span
                  style={{
                    padding: '0.2rem 0.5rem'
                  }}
                >
                  {' '}
                  Manage invoices
                </span>
              </Link>{' '}
            </u>
          </section>

          <section>
            <h4>Security</h4>
            <div>
              <PasswordChanger />
            </div>
          </section>
        </Box>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => setOpenDelete(true)}
          style={{ background: 'red' }}
          variant="contained"
        >
          Delete Account
        </Button>
      </CardActions>
    </Card>
  );
};
