import { Typography } from "@mui/material";
import Analytics from "./Analytics";
import { H2 } from "app/components/Typography";


const Dashboard = () => {
  return (
    <div>
      <H2>Dashboard</H2>
      <Analytics />
    </div>
  );
};

export default Dashboard;
