//Camera Brand, Camera Model, Exposure Time, F-Number, ISO speed ratings, Focal length, Lens Model, Flash, Metering Mode, Exposure Mode

import useAuth from 'app/hooks/useAuth';
import { addDoc, collection, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import exifAtom from './exifAtom';
import { LoadingButton } from '@mui/lab';
import { Copyright, Description, Title } from '@mui/icons-material';

const {
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  Box,
  MenuItem
} = require('@mui/material');
const { toast } = require('react-toastify');

export const defaultExifValue = {
  name: '',

  Title: '',
  Description: '',
  AltText: '',
  Website: '',
  Copyright: '',
  Author: '',
  Keywords: '',
  CameraBrand: '',
  CameraModel: '',
  ExposureTime: '',
  FNumber: '',
  ISOSpeedRatings: '',
  FocalLength: '',
  LensModel: '',
  Flash: 'No',
  MeteringMode: '',
  ExposureMode: '',
  GPSinformation: '',
  Software: ''
};

const AddExifDialog = ({ open, setOpen, editing, setEditing }) => {
  const [values, setValues] = useState(defaultExifValue);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [exifData, setExifData] = useRecoilState(exifAtom);

  useEffect(() => {
    if (exifData && editing >= 0) {
      setValues({ ...exifData[editing] });
    }
  }, [editing, exifData]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (editing >= 0) {
        await updateDoc(
          doc(getFirestore(), 'userData', user.uid, 'exifs', exifData[editing].id),
          values
        );
        setEditing(-1);
        setExifData(exifData.map((item, index) => (index === editing ? values : item)));
      } else {
        await addDoc(collection(getFirestore(), 'userData', user.uid, 'exifs'), values);
        setExifData([...exifData, values]);
      }
      setValues(defaultExifValue);
      setOpen(false);
    } catch (e) {
      console.error(e);
      toast.error('Failed to add Exif data');
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{editing >= 0 ? 'Edit' : 'Add'} EXIF Data</DialogTitle>

      <DialogContent>
        <DialogContentText marginBottom="10px">Give the layout a name</DialogContentText>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={12} padding={1}>
              <TextField
                fullWidth
                label="Name of the EXIF layout"
                name="name"
                placeholder='e.g. "My EXIF Layout"'
                onChange={handleChange}
                value={values.name}
              />
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <DialogContentText marginBottom="10px">
          EXIF data for your generated images
        </DialogContentText>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Author Name"
                name="Author"
                placeholder="Name of the author"
                onChange={handleChange}
                value={values.Author}
              />
            </Grid>

            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Copyright"
                name="Copyright"
                placeholder=""
                onChange={handleChange}
                value={values.Copyright}
              />
            </Grid>

            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Website"
                name="Website"
                placeholder='eg. "abc.com'
                onChange={handleChange}
                value={values.Website}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Camera Brand"
                name="CameraBrand"
                placeholder='e.g. "Canon"'
                onChange={handleChange}
                value={values.CameraBrand}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Camera Model"
                name="CameraModel"
                placeholder='e.g. "Canon EOS 5D Mark IV"'
                onChange={handleChange}
                value={values.CameraModel}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Exposure Time"
                name="ExposureTime"
                placeholder='e.g. "1/1000"'
                onChange={handleChange}
                value={values.ExposureTime}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="F-Number"
                name="FNumber"
                placeholder='e.g. "f/2.8"'
                onChange={handleChange}
                value={values.FNumber}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="ISO Speed Ratings"
                name="ISOSpeedRatings"
                placeholder='e.g. "100"'
                onChange={handleChange}
                value={values.ISOSpeedRatings}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Focal Length"
                name="FocalLength"
                placeholder='e.g. "50mm"'
                onChange={handleChange}
                value={values.FocalLength}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Lens Model"
                name="LensModel"
                placeholder='e.g. "EF 24-70mm f/2.8L II USM"'
                onChange={handleChange}
                value={values.LensModel}
              />
            </Grid>
            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                select
                label="Flash"
                name="Flash"
                onChange={handleChange}
                value={values.Flash}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </Grid>

            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="Exposure Mode"
                name="ExposureMode"
                placeholder='e.g. "Manual"'
                onChange={handleChange}
                value={values.ExposureMode}
              />
            </Grid>

            <Grid xs={12} md={6} padding={1}>
              <TextField
                fullWidth
                label="GPS Information"
                name="GPSinformation"
                placeholder="e.g. 12.345678, 98.765432"
                onChange={handleChange}
                value={values.GPSinformation}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <LoadingButton loading={loading} onClick={handleSubmit} color="primary" disabled={loading}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddExifDialog;
