import { Button, Card, styled } from "@mui/material";
import useAuth from "app/hooks/useAuth";
import { convertHexToRGB } from "app/utils/utils";
import { useNavigate } from "react-router-dom";

const CardRoot = styled(Card)(({ theme }) => ({
  marginBottom: "24px",
  padding: "24px !important",
  [theme.breakpoints.down("sm")]: { paddingLeft: "16px !important" },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  position: "relative",
  padding: "24px !important",
  background: `rgb(${convertHexToRGB(
    theme.palette.primary.main
  )}, 0.15) !important`,
  [theme.breakpoints.down("sm")]: { padding: "16px !important" },
}));

const Paragraph = styled("p")(({ theme }) => ({
  margin: 0,
  paddingTop: "24px",
  paddingBottom: "24px",
  color: theme.palette.text.secondary,
}));

const UpgradeCard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <CardRoot>
      <StyledCard elevation={0}>
        <img src="/assets/images/illustrations/upgrade.svg" alt="upgrade" />

        <Paragraph>
          You are currently using our <b> {user.plan.name} </b> plan.
          <br />
          Choose the plan that best fits your needs and enjoy the <br />
          Midjourney experience.
        </Paragraph>

        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{ textTransform: "uppercase" }}
          onClick={() => navigate("/pricing")}
        >
          View Plans
        </Button>
      </StyledCard>
    </CardRoot>
  );
};

export default UpgradeCard;
