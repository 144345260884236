import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const APIKeys = ({ site, updateSite, userData, selectedSite }) => {
  return (
    <Card>
      <Typography variant="h6" sx={{ color: '#1976d2', padding: '12px 16px', fontWeight: 500 }}>
        Generation Credentials
      </Typography>
      <Divider />
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">Select OpenAI key</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', maxWidth: '70%' }}>
            All the AI related works for generating content for this site will be done using this
            OpenAI key. You can manage your added keys from{' '}
            <Link style={{ color: 'blue' }} to="/credentials">
              credentials
            </Link>{' '}
            tab.
          </Typography>

          <Select
            value={site.settings.openai_key}
            onChange={(e) => {
              updateSite({
                settings: { openai_key: e.target.value }
              });
            }}
            sx={{ width: '300px', marginTop: 2 }}
          >
            {userData?.openai_keys?.map((key) => (
              <MenuItem key={key.key} value={key.key}>
                {key.name}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ flexGrow: 1, marginTop: '20px' }}>
          <Typography variant="subtitle1">Select discord credential</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Images will be generated using this Discord credential for this site.
          </Typography>

          <Select
            value={site.settings.selected_dc_cred || userData.selected_dc_cred}
            onChange={(e) => {
              updateSite({
                settings: { selected_dc_cred: parseInt(e.target.value) }
              });
            }}
            sx={{ width: '300px', marginTop: 2 }}
          >
            {userData?.dc_creds?.map((key, i) => (
              <MenuItem key={key.name} value={i}>
                {key.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </CardContent>
    </Card>
  );
};

export default APIKeys;
