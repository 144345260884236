import { useState } from 'react';
import { Box, TextField, useTheme, Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LoadingButton } from '@mui/lab';
import { auth } from '../../../firebase';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

export default function DeleteDialog({ op, setOp }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [typedName, setTypedName] = useState('');
  const [isLoading, setIsLoading] = useState(-1);

  const handleDelete = async () => {
    setIsLoading(0);

    try {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, typedName);
      await reauthenticateWithCredential(auth.currentUser, credential);

      await auth.currentUser.delete();
    } catch (e) {
      setIsLoading(2);
      return;
    }
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={op}
        onClose={() => setOp(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Account Delete</DialogTitle>

        <DialogContent>
          <DialogContentText>
            All of your data will be permanently deleted from our database and this action cannot be
            undone. Enter your password to continue.
            <br></br>
            <br></br>
            <center>
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                placeholder="password"
                value={typedName}
                onChange={(e) => setTypedName(e.target.value)}
              ></TextField>
            </center>
            <center>
              {isLoading > 0 && (
                <Box m={2}>
                  <Alert severity={'error'}>
                    {isLoading == 1 && 'Could not delete account'}
                    {isLoading == 2 && 'Wrong password'}
                  </Alert>
                </Box>
              )}
            </center>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOp(false)} color="primary" variant="contained">
            Cancel
          </Button>

          <LoadingButton
            disabled={typedName.trim().length == 0}
            onClick={handleDelete}
            style={{ width: '120px' }}
            variant="outlined"
            color="secondary"
            loading={isLoading == 0}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
