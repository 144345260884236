import { AutoAwesome, Language, Storage } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import { defaultExifValue } from 'app/views/exif/AddExifDialog';
import exifAtom from 'app/views/exif/exifAtom';
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { exifSelectionAtom } from './atom';

const ExifSelectorDialog = ({ subject }) => {
  const [exifData, setExifData] = useRecoilState(exifAtom);
  const [userData, setUserData] = useState(null);
  const { user } = useAuth();
  const [openExifChooser, setOpenExifChooser] = useState(false);
  const [exifSelectionState, setExifSelectionState] = useRecoilState(exifSelectionAtom);
  const [aiExifLoading, setAiExifLoading] = useState(false);
  const [aiExifLanguage, setAiExifLanguage] = useState('sm');

  useEffect(() => {
    const fetchData = async () => {
      const exifs = [];
      const querySnapshot = await getDocs(
        collection(getFirestore(), 'userData', user.uid, 'exifs')
      );
      querySnapshot.forEach((doc) => {
        exifs.push({ id: doc.id, ...doc.data() });
      });
      setExifData(exifs);
    };

    if (!exifData) fetchData();
  }, [exifData]);

  useEffect(() => {
    const getUser = async () => {
      const docRef = doc(getFirestore(), 'userData', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserData(docSnap.data());
      }
    };

    getUser();
  }, []);

  const getAIExif = async () => {
    setAiExifLoading(true);
    try {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const { data } = await fn({
        openAIKey: userData.openai_key,
        subject: subject,
        language: aiExifLanguage === 'sm' ? '' : aiExifLanguage,
        command: 'getAIExif'
      });

      setExifSelectionState((p) => ({
        ...p,
        exif: {
          ...p.exif,
          ...data
        }
      }));
    } catch (e) {
      console.error(e);
      toast.error('Failed to add EXIF data from ai');
    }
    setAiExifLoading(false);
  };

  const handleChange = (e) => {
    setExifSelectionState({
      ...exifSelectionState,
      exif: { ...exifSelectionState.exif, [e.target.name]: e.target.value }
    });
  };

  return (
    <Dialog
      open={exifSelectionState.open}
      onClose={() =>
        setExifSelectionState((p) => ({
          ...p,
          open: false
        }))
      }
    >
      <DialogTitle>Select EXIF</DialogTitle>

      <DialogContent>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: 'flex',
              padding: '8px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenExifChooser(true)}
              startIcon={<Storage />}
            >
              My EXIFs
            </Button>

            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => setExifSelectionState((p) => ({ ...p, exif: defaultExifValue }))}
              >
                clear
              </Button>

              <TextField
                select
                size="small"
                label="AI EXIF language"
                value={aiExifLanguage}
                onChange={(e) => setAiExifLanguage(e.target.value)}
              >
                <MenuItem value="sm">Same as prompt</MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="German">German</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
                <MenuItem value="Italian">Italian</MenuItem>
                <MenuItem value="Dutch">Dutch</MenuItem>
                <MenuItem value="Portuguese">Portuguese</MenuItem>
              </TextField>

              <LoadingButton
                variant="contained"
                loading={aiExifLoading}
                color="primary"
                size="small"
                onClick={getAIExif}
                startIcon={<AutoAwesome />}
              >
                AI EXIF
              </LoadingButton>
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 1, marginTop: '18px' }}>
            <Grid container>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Title"
                  name="Title"
                  placeholder="Title of the image"
                  onChange={handleChange}
                  value={exifSelectionState.exif?.Title}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Description"
                  name="Description"
                  placeholder="Description of the image"
                  onChange={handleChange}
                  value={exifSelectionState.exif?.Description}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Alt Text"
                  name="AltText"
                  placeholder="Alt text for the image"
                  onChange={handleChange}
                  value={exifSelectionState.exif?.['AltText']}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Keywords"
                  name="Keywords"
                  placeholder="Keywords for the image"
                  onChange={handleChange}
                  value={exifSelectionState.exif?.['Keywords']}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Author Name"
                  name="Author"
                  placeholder="Name of the author"
                  onChange={handleChange}
                  value={exifSelectionState.exif?.Author}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Copyright"
                  name="Copyright"
                  placeholder=""
                  onChange={handleChange}
                  value={exifSelectionState.exif?.Copyright}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Website"
                  name="Website"
                  placeholder='eg. "abc.com'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.Website}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Software"
                  name="Software"
                  placeholder='eg. "Adobe Photoshop CC"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.Software}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Camera Brand"
                  name="CameraBrand"
                  placeholder='e.g. "Canon"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.CameraBrand}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Camera Model"
                  name="CameraModel"
                  placeholder='e.g. "Canon EOS 5D Mark IV"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.CameraModel}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Exposure Time"
                  name="ExposureTime"
                  placeholder='e.g. "1/1000"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.ExposureTime}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="F-Number"
                  name="FNumber"
                  placeholder='e.g. "f/2.8"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.FNumber}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="ISO Speed Ratings"
                  name="ISOSpeedRatings"
                  placeholder='e.g. "100"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.ISOSpeedRatings}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Focal Length"
                  name="FocalLength"
                  placeholder='e.g. "50mm"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.FocalLength}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Lens Model"
                  name="LensModel"
                  placeholder='e.g. "EF 24-70mm f/2.8L II USM"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.LensModel}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  select
                  label="Flash"
                  name="Flash"
                  onChange={handleChange}
                  value={exifSelectionState.exif?.Flash}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="Exposure Mode"
                  name="ExposureMode"
                  placeholder='e.g. "Manual"'
                  onChange={handleChange}
                  value={exifSelectionState.exif?.ExposureMode}
                />
              </Grid>

              <Grid xs={12} md={6} padding={1}>
                <TextField
                  fullWidth
                  label="GPS Information"
                  name="GPSinformation"
                  placeholder="e.g. 12.345678, 98.765432"
                  onChange={handleChange}
                  value={exifSelectionState.exif?.GPSinformation}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setExifSelectionState((p) => ({
              ...p,
              open: false
            }));
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setExifSelectionState((p) => ({
              ...p,
              open: false
            }));
            exifSelectionState.nextCallback(exifSelectionState.exif);
          }}
        >
          Next
        </Button>
      </DialogActions>

      <Dialog open={openExifChooser} onClose={() => setOpenExifChooser(false)}>
        <DialogTitle>Choose EXIF data</DialogTitle>

        <DialogContent>
          {exifData?.length === 0 && (
            <Typography variant="body1" color="text.secondary" maxWidth="300px">
              You don't have any saved EXIF data. Add some EXIF layout from the
              <Link
                to="/exifs"
                style={{
                  textDecoration: 'none',
                  color: '#1976d2',
                  fontWeight: 500
                }}
              >
                {' '}
                My EXIF{' '}
              </Link>
              page.
            </Typography>
          )}
          {exifData?.map((exif, index) => (
            <Box
              key={index}
              border="1px solid #ccc"
              borderRadius="5px"
              padding="10px"
              marginBottom="10px"
              minWidth="300px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setExifSelectionState((p) => ({
                  ...p,
                  exif: exif,
                  exifType: 'manual'
                }));
                setOpenExifChooser(false);
              }}
            >
              <Typography variant="body1">{exif.name}</Typography>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenExifChooser(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ExifSelectorDialog;
