import { useState } from 'react';
import { Box, TextField, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { set } from 'lodash';
import { useEffect } from 'react';

export default function NameDialog({ setName, op, dName }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [typedName, setTypedName] = useState(dName);

  useEffect(() => {
    setTypedName(dName);
  }, [dName]);

  const handleClose = () => {
    setName(typedName);
  };

  const handleCancel = () => {
    setName(null);
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={op}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Enter name</DialogTitle>

        <DialogContent>
          <DialogContentText>
            This name will be used to identify this specific prompt. You can search by this name in
            your saved prompt page.
            <br></br>
            <br></br>
            <center>
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                placeholder="Name of the prompt"
                value={typedName}
                onChange={(e) => setTypedName(e.target.value)}
              ></TextField>
            </center>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>

          <Button
            disabled={typedName.trim().length == 0}
            onClick={handleClose}
            color="primary"
            variant="contained"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
