import useAuth from 'app/hooks/useAuth';
import useSettings from 'app/hooks/useSettings';
import { db } from '../../../firebase';
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';
import { useEffect } from 'react';
import { useState } from 'react';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Loading from 'app/components/MatxLoading';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '../wordpress/AccordionStyle';
import { LoadingButton } from '@mui/lab';
import UpgradeDialog from '../UpgradePlanDialog';
import VerifyEmail from '../VerifyEmail';
import { H2 } from 'app/components/Typography';
import {
  ArrowBack,
  AutoAwesomeMosaic,
  AutoAwesomeMotion,
  AutoAwesomeOutlined,
  RefreshOutlined
} from '@mui/icons-material';
import { set } from 'lodash';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

const SecondStage = ({ seedText, presets, setMsg, setPlanMsg, openai_key }) => {
  const { user } = useAuth();
  const [GPTresult, setGPTresult] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(-1);
  const [selectedPrompt, setSelectedPrompt] = useState(0);
  const [selectedAR, setSelectedAR] = useState('--ar 3:2');
  const [prompt, setPrompt] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [gptPrompt, setGptPrompt] = useState(null);
  const [selectGPTprompt, setSelectedGPTprompt] = useState('classic');

  useEffect(() => {
    if (seedText) {
      const fetchData = async () => {
        setIsRefreshing(true);
        const fn = httpsCallable(getFunctions(), 'magicGenerator');
        const data = await fn({
          type: 'smartGenerateGPTIdea',
          prompt: seedText,
          openai_key,
          testPrompt: gptPrompt,
          promptName: selectGPTprompt
        });

        if (data.data.error) {
          setMsg({ msg: data.data.error, sv: 'error' });
          setGPTresult([]);
        } else setGPTresult(data.data);
        setIsRefreshing(false);
        console.log(data);
      };

      try {
        fetchData();
      } catch (e) {
        console.log(e);
        toast.error('Something went wrong. Please try again later');
      }
    }
  }, [seedText, refresh]);

  useEffect(() => {
    if (!GPTresult || GPTresult.length == 0) return;

    let newPrompt = GPTresult[selectedPrompt];

    if (selectedPreset >= 0) {
      const preset = presets[selectedPreset].preset;
      newPrompt = preset.replace('[SUBJECT]', newPrompt);
    }

    if (newPrompt.includes('[SIZE]')) {
      newPrompt = newPrompt.replace('[SIZE]', selectedAR);
    } else {
      newPrompt += ' ' + selectedAR;
    }

    setPrompt(newPrompt);
  }, [selectedPrompt, selectedPreset, selectedAR, GPTresult]);

  if (!GPTresult)
    return (
      <Stack width="100%">
        <Loading />
      </Stack>
    );

  const generateHandler = async () => {
    if (!user.plan.saas.generator) return;

    setIsGenerating(true);

    try {
      const fn = httpsCallable(getFunctions(), 'generateImage');
      const { data } = await fn({
        prompt,
        subject: GPTresult[selectedPrompt]
      });
      if (data.error) {
        console.log({ data });
        if (data.error?.includes('Rate limit')) {
          setPlanMsg('You have reached your daily limit. Please upgrade your plan to continue.');

          return;
        } else setMsg({ msg: data.error, sv: 'error' });
      } else {
        window.open('/track/' + data.trackID, '_blank');
      }
    } catch (err) {
      console.log({ err });
      setMsg({
        msg: 'Something went wrong. Please try again later',
        sv: 'error'
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Stack width="100%" justifyContent="center" alignItems="center">
      <div
        style={{
          maxWidth: '1000px',
          width: '100%',
          padding: '20px 10px'
        }}
      >
        {user.plan.name === 'Admin' && (
          <TextField
            variant="outlined"
            multiline
            maxRows={10}
            rows={8}
            fullWidth
            label="Test Prompt for GPT"
            value={gptPrompt}
            onChange={(e) => setGptPrompt(e.target.value)}
          />
        )}

        <Stack direction={'row'} justifyContent="space-between">
          <h3>Choose a Suggestion</h3>
          <LoadingButton
            loading={isRefreshing}
            disabled={isRefreshing}
            onClick={() => setRefresh((p) => !p)}
            startIcon={<RefreshOutlined />}
          >
            refresh suggestions
          </LoadingButton>
        </Stack>
        {user.plan.GPTPrompts && (
          <TextField
            select
            value={selectGPTprompt}
            onChange={(e) => setSelectedGPTprompt(e.target.value)}
            variant="outlined"
            label="Suggestion Style"
            style={{ width: '50%', marginBottom: '20px' }}
          >
            {user.plan.GPTPrompts.map((prompt, i) => (
              <MenuItem key={i} value={prompt}>
                {prompt}
              </MenuItem>
            ))}
          </TextField>
        )}
        <RadioGroup
          value={selectedPrompt}
          defaultChecked={0}
          onChange={(e) => setSelectedPrompt(e.target.value)}
        >
          <Grid container>
            {GPTresult.map((res, i) => (
              <Grid item xs={12} sm={6} key={i}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  <FormControlLabel value={i} control={<Radio />} label={res} />
                </Typography>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>

        <h3>Result</h3>
        <TextField
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          multiline
          maxRows={10}
          rows={8}
          fullWidth
          label="Generated Prompt"
        />

        <Stack
          height="100%"
          padding="8px 2px"
          marginTop="20px"
          justifyContent="space-between"
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <TextField
            select
            value={selectedPreset}
            onChange={(e) => setSelectedPreset(e.target.value)}
            variant="outlined"
            label="Presets"
            fullWidth
          >
            <MenuItem value={-1}>None</MenuItem>
            {presets &&
              presets.map((preset, i) => (
                <MenuItem key={preset.id} value={i}>
                  {preset.name}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            select
            value={selectedAR}
            onChange={(e) => setSelectedAR(e.target.value)}
            variant="outlined"
            label="Aspect Ratio"
            fullWidth
            style={{ width: '100%' }}
          >
            <MenuItem value="--ar 1:1">1:1</MenuItem>
            <ListSubheader>Horizontal</ListSubheader>
            <MenuItem value="--ar 3:2">3:2</MenuItem>
            <MenuItem value="--ar 16:9">16:9</MenuItem>
            <MenuItem value="--ar 5:4">5:4</MenuItem>
            <MenuItem value="--ar 7:4">7:4</MenuItem>

            <ListSubheader>Vertical</ListSubheader>
            <MenuItem value="--ar 2:3">2:3</MenuItem>
            <MenuItem value="--ar 9:16">9:16</MenuItem>
            <MenuItem value="--ar 4:5">4:5</MenuItem>
            <MenuItem value="--ar 7:4">7:4</MenuItem>
          </TextField>

          <LoadingButton
            onClick={generateHandler}
            loading={isGenerating}
            disabled={!user.plan.saas.generator || isGenerating}
            fullWidth
            variant="contained"
          >
            Generate Image
          </LoadingButton>
        </Stack>
      </div>
    </Stack>
  );
};
const SmartGenerator = () => {
  const { user } = useAuth();

  const { settings, replaceAllPresets } = useSettings();
  const [presets, setPresets] = useState(settings.presets);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [prompt, setPrompt] = useState('');
  const [isTextBox, setIsTextBox] = useState('');

  const [msg, setMsg] = useState(null);
  const [planMsg, setPlanMsg] = useState(null);
  const confirm = useConfirm();
  useEffect(() => {
    const getUserData = async () => {
      const dc = await getDoc(doc(getFirestore(), 'userData', user.uid));
      setUserData(dc.data());
    };

    const getPresets = async () => {
      const ref = collection(db, 'userData', user.uid, 'presets');
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      setPresets(nPrompts);
      replaceAllPresets(nPrompts);
    };

    if (!presets) getPresets();

    getUserData();
  }, []);
  useEffect(() => {
    if (content.length > 80) setIsTextBox(true);
    else setIsTextBox(false);
  }, [content]);

  if (!user.emailVerified) return <VerifyEmail />;

  if (!userData) return <Loading />;

  if (!userData.openai_key)
    return (
      <Dialog open={true}>
        <DialogTitle>API key is needed</DialogTitle>
        <DialogContent>
          You need to save your OpenAI API key to use the Magic Generator. Please go to the
          credentials settings page to save your API key.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => navigate('/credentials')}>
            Go to settings
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Stack>
      <H2>
        <Stack direction="row" alignItems="center" gap={1}>
          {prompt && <ArrowBack color="primary" onClick={() => setPrompt('')} />}
          Magic Generator
        </Stack>
      </H2>

      <Snackbar
        open={!!msg}
        autoHideDuration={3000}
        onClose={() => setMsg(null)}
        sx={{ height: '15%' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert sx={{ m: 1 }} severity={msg?.sv} variant="filled">
          {msg?.msg}
        </Alert>
      </Snackbar>

      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />
      {!prompt && (
        <Stack justifyContent="center" alignItems="center" padding="0 10px" height="70vh">
          <Stack width="100%" maxWidth={isTextBox ? '60%' : '100%'}>
            <TextField
              autoFocus={true}
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none'
                  }
                },
                mb: 2
              }}
              inputProps={{
                min: 0,
                style: {
                  fontSize: isTextBox ? '24px' : '35px',
                  padding: '10px',
                  lineHeight: '40px',
                  textAlign: isTextBox ? 'justify' : 'center'
                }
              }}
              multiline={isTextBox}
              width
              placeholder="What's on your mind?"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && content && setPrompt(content)}
            />
          </Stack>
          <Stack spacing={2} style={{ maxWidth: '400px', width: '100%', marginTop: '30px' }}>
            <Button
              fullWidth
              startIcon={<AutoAwesomeOutlined />}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                if (content) setPrompt(content);
                else {
                  confirm({
                    title: 'No text entered',
                    content:
                      'You have not entered any text. Please enter some topic or detailed description to get suggestions.',
                    confirmationText: 'OK',
                    cancellationText: false
                  });
                }
              }}
            >
              Get Suggestion
            </Button>
          </Stack>
        </Stack>
      )}

      {prompt && (
        <SecondStage
          seedText={prompt}
          presets={presets}
          openai_key={userData.openai_key}
          setMsg={setMsg}
          setPlanMsg={setPlanMsg}
        />
      )}
    </Stack>
  );
};

export default SmartGenerator;
