import { Alert, Button, Icon, Typography } from '@mui/material';
import { pad } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';

export default function PaymentCancel() {
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          padding: '50px',
          textAlign: 'center',
          flexDirection: 'column'
        }}
      >
        <Icon
          style={{
            fontSize: '35px',
            color: 'red'
          }}
        >
          cancel
        </Icon>
        <h1>Payment Cancelled</h1>
        <div>
          We are sorry that you cancelled the payment. You can try again by clicking the button
          below.
        </div>
        <br></br>

        <Button variant="contained" onClick={() => navigate('/pricing')}>
          Pricing
        </Button>
      </div>
    </div>
  );
}
