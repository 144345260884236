import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotAvailableForPlan = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Dialog open={true}>
        <DialogTitle>Not Available For Your Plan</DialogTitle>
        <DialogContent>
          <p>
            This feature is not available for your plan. Please upgrade your
            plan to access this feature.
          </p>
        </DialogContent>
        <DialogActions>

          <Button
          
          onClick={() => navigate("/")}
          variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => navigate("/pricing")}
            variant="contained"
            color="primary"
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotAvailableForPlan;
