import {
  Box,
  Button,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { use } from 'echarts';
import { useEffect, useState } from 'react';
import { MatxSearchBox } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { blue, red } from '@mui/material/colors';
import { H2 } from 'app/components/Typography';
import AlertDialog from './DeleteDialog';
import { LoadingButton } from '@mui/lab';
import Loading from 'app/components/MatxLoading';
import { settings } from 'firebase/analytics';
import { db } from '../../../firebase';
import { collection, doc, addDoc, updateDoc, getDocs, deleteDoc } from 'firebase/firestore';
import NameDialog from '../generator/NameDialog';
import UpgradeDialog from '../UpgradePlanDialog';
import makePreset from './makePreset';
import { Searchbar } from '../presets';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));


const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}));

const ButtonPanel = ({
  item,
  deleteItem,
  delLoading,
  duplicate,
  rename,
  renameLoading,
  genPreset,
  disabled
}) => {
  const { replaceGenPanel } = useSettings();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [genPresetLoading, setGenPresetLoading] = useState(false);
  const [presetOk, setPresetOk] = useState('');
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();

  useEffect(() => {
    if (presetOk === 'ok') {
      setTimeout(() => {
        setPresetOk('');
      }, 2000);
    }
  }, [presetOk]);

  return (
    <FlexBox
      style={{
        justifyContent: 'center',
        width: '100%',
        flexDirection: !matches ? 'column' : 'row'
      }}
    >
      <LoadingButton
        color="error"
        disabled={disabled}
        loading={delLoading}
        onClick={() => deleteItem(item.id)}
      >
        {!delLoading && (
          <Tooltip title="delete" enterTouchDelay={0}>
            <Icon>delete</Icon>
          </Tooltip>
        )}
      </LoadingButton>

      <LoadingButton
        disabled={disabled}
        loading={renameLoading}
        onClick={() => {
          rename(item.id);
        }}
      >
        <Tooltip title="rename" enterTouchDelay={0}>
          <Icon>drive_file_rename_outline</Icon>
        </Tooltip>
      </LoadingButton>

      <LoadingButton
        disabled={disabled}
        loading={genPresetLoading}
        onClick={() => {
          setGenPresetLoading(true);
          genPreset(item)
            .then(() => {
              setGenPresetLoading(false);
              setPresetOk('ok');
            })
            .catch((err) => {
              setGenPresetLoading(false);
            });
        }}
      >
        {presetOk === '' && (
          <Tooltip title="convert to a preset" enterTouchDelay={0}>
            <Icon>code</Icon>
          </Tooltip>
        )}
        {presetOk === 'ok' && 'Saved'}
      </LoadingButton>

      <LoadingButton
        disabled={disabled}
        loading={isDuplicating}
        onClick={async () => {
          setIsDuplicating(true);
          await duplicate(item.id);
          setIsDuplicating(false);
        }}
      >
        <Tooltip title="make a copy" enterTouchDelay={0}>
          <Icon>content_copy</Icon>
        </Tooltip>
      </LoadingButton>

      <Button
        disabled={disabled}
        onClick={() => {
          replaceGenPanel(item);
          navigate('/prompt_generator');
        }}
      >
        <Tooltip title="edit prompt" enterTouchDelay={0}>
          <Icon style={{ color: 'black' }}>arrow_right_alt</Icon>
        </Tooltip>
      </Button>
    </FlexBox>
  );
};

const SavedPrompts = () => {
  const { settings, replaceAllPrompts, replaceAllPresets } = useSettings();
  const [data, setData] = useState(settings.prompts);
  const [presets, setPresets] = useState(settings.presets);
  const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToRename, setItemToRename] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [nameOpen, setNameOpen] = useState(false);
  const [planMsg, setPlanMsg] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    if (!data) return;
    setFilteredData(
      data.filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          (item.data.subject && item.data.subject.toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [data, search]);

  useEffect(() => {
    const getPresets = async () => {
      const ref = collection(db, 'userData', user.uid, 'presets');
      const snapshot = await getDocs(ref);
      let nPresets = [];
      snapshot.forEach((doc) => {
        nPresets.push({ ...doc.data(), id: doc.id });
      });
      setPresets(nPresets);
      replaceAllPresets(nPresets);
    };

    if (!presets) {
      getPresets();
    }
  }, []);

  useEffect(() => {
    if (itemToDelete) setDeleteOpen(true);
  }, [itemToDelete]);

  useEffect(() => {
    if (itemToRename) setNameOpen(true);
  }, [itemToRename]);

  useEffect(() => {
    if (data) return;

    const getPrompts = async () => {
      const ref = collection(db, 'userData', user.uid, 'prompts');
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      setData(nPrompts);
      replaceAllPrompts(nPrompts);
    };

    getPrompts();
  }, []);

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  const genPreset = async (item) => {
    if (presets.length >= user.plan.saas.save_presets) {
      setPlanMsg(
        'You can save upto ' +
          user.plan.saas.save_presets +
          ' presets. You must delete some saved presets to make room for the new ones.'
      );
      return;
    }

    const preset = makePreset(item);
    const ref = collection(db, 'userData', user.uid, 'presets');
    const resp = await addDoc(ref, preset);

    preset.id = resp.id;

    const nPresets = [...presets, preset];
    replaceAllPresets(nPresets);
    setPresets([...nPresets]);
  };

  const deleteItem = async (proceed) => {
    setDeleteOpen(false);
    if (!proceed) {
      setItemToDelete(null);
      return;
    }

    const id = itemToDelete;
    await deleteDoc(doc(db, 'userData', user.uid, 'prompts', id));
    replaceAllPrompts(data.filter((item) => item.id != itemToDelete));
    setData(data.filter((item) => item.id != itemToDelete));
    setItemToDelete(null);
  };

  const renameItem = async (name) => {
    setNameOpen(false);

    if (!name || !itemToRename) {
      setItemToRename(null);
      return;
    }

    const index = data.findIndex((item) => item.id == itemToRename);

    const ndata = [...data];
    ndata[index].name = name;

    const ref = doc(db, 'userData', user.uid, 'prompts', ndata[index].id);
    await updateDoc(ref, ndata[index]);

    setData(data);
    replaceAllPrompts([...data]);
    setItemToRename(null);
  };

  const duplicateItem = async (id) => {
    if (data.length >= user.plan.saas.save_prompts) {
      setPlanMsg(
        'You can save upto ' +
          user.plan.saas.save_prompts +
          ' prompts. You must delete some saved prompts to make room for the new ones.'
      );
      return;
    }
    const nval = { ...data.find((item) => item.id == id) };
    const ndata = [...data, nval];

    const ref = collection(db, 'userData', user.uid, 'prompts');
    nval.id = ref.id;
    await addDoc(ref, nval);

    setData(ndata);
    replaceAllPrompts(ndata);
  };

  return (
    <div>
      <AlertDialog
        op={deleteOpen}
        setResult={deleteItem}
        name={itemToDelete ? data.find((item) => item.id == itemToDelete).name : ''}
      ></AlertDialog>

      <NameDialog
        op={nameOpen}
        setName={renameItem}
        dName={itemToDelete ? data.find((item) => item.id == itemToDelete).name : ''}
      />

      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />

      <FlexBox style={{ marginTop: '25px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>My Prompts</H2>
      </FlexBox>
      <FlexBox>
        <Box
          sx={{ padding: '10px 20px' }}
          md={{ padding: '15px 25px' }}
          style={{ maxWidth: '1500px' }}
        >
          <TableContainer>
            <FlexBox style={{ justifyContent: 'right' }}>
              <Searchbar setSearch={setSearch} />
            </FlexBox>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="25%">
                    <b>Name</b>
                  </TableCell>
                  <TableCell align="center" width="50%%">
                    <b>Prompt</b>
                  </TableCell>
                  <TableCell align="center" width="25%">
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!filteredData && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}

                {filteredData && filteredData.length == 0 && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <div
                        style={{
                          width: '100%',
                          height: '50px',
                          marginTop: '20px',
                          textAlign: 'center'
                        }}
                      >
                        <h3>No prompts found</h3>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {filteredData &&
                  filteredData.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.prompt}</TableCell>
                      <TableCell>
                        <ButtonPanel
                          disabled={index >= user.plan.saas.save_prompts}
                          item={item}
                          index={item.id}
                          deleteItem={setItemToDelete}
                          rename={setItemToRename}
                          duplicate={duplicateItem}
                          delLoading={itemToDelete == item.id}
                          renameLoading={itemToRename == item.id}
                          genPreset={genPreset}
                        ></ButtonPanel>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </FlexBox>
    </div>
  );
};

export default SavedPrompts;
