import {
  Box,
  Button,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { use } from 'echarts';
import { useEffect, useState } from 'react';
import { MatxSearchBox } from 'app/components';
import './search.css';
import useSettings from 'app/hooks/useSettings';
import { blue, red } from '@mui/material/colors';
import { H2 } from 'app/components/Typography';
import AlertDialog from './DeleteDialog';
import { LoadingButton } from '@mui/lab';
import Loading from 'app/components/MatxLoading';
import { settings } from 'firebase/analytics';
import { db } from '../../../firebase';
import { collection, doc, addDoc, updateDoc, getDocs, deleteDoc } from 'firebase/firestore';
import NameDialog from '../generator/NameDialog';
import UpgradeDialog from '../UpgradePlanDialog';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

export const Searchbar = ({ setSearch, placeholder }) => {
  return (
    <div class="search-box">
      <input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        class="search-text"
        type="text"
        placeholder={placeholder || "Search by name or subject"}
      />
      <a href="#" class="search-btn">
        <Icon>search</Icon>
      </a>
    </div>
  );
};

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}));

const ButtonPanel = ({ deleteItem, id, delLoading, rename, renameLoading, disabled }) => {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <FlexBox
      style={{
        justifyContent: 'center',
        width: '100%',
        flexDirection: !matches ? 'column' : 'row'
      }}
    >
      <LoadingButton
        disabled={disabled}
        color="error"
        loading={delLoading}
        onClick={() => deleteItem(id)}
      >
        {!delLoading && (
          <Tooltip title="delete" enterTouchDelay={0}>
            <Icon>delete</Icon>
          </Tooltip>
        )}
      </LoadingButton>

      <LoadingButton
        disabled={disabled}
        loading={renameLoading}
        onClick={() => {
          rename(id);
        }}
      >
        <Tooltip title="rename" enterTouchDelay={0}>
          <Icon>drive_file_rename_outline</Icon>
        </Tooltip>
      </LoadingButton>
    </FlexBox>
  );
};

const SavedPresets = () => {
  const { settings, replaceAllPresets } = useSettings();
  const [data, setData] = useState(settings.presets);
  const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToRename, setItemToRename] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [nameOpen, setNameOpen] = useState(false);
  const [planMsg, setPlanMsg] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    if (!data) return;
    setFilteredData(
      data.filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.preset.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [data, search]);

  useEffect(() => {
    if (itemToDelete) setDeleteOpen(true);
  }, [itemToDelete]);

  useEffect(() => {
    if (itemToRename) setNameOpen(true);
  }, [itemToRename]);

  useEffect(() => {
    if (data) return;

    const getPrompts = async () => {
      const ref = collection(db, 'userData', user.uid, 'presets');
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      setData(nPrompts);
      replaceAllPresets(nPrompts);
    };

    getPrompts();
  }, []);

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  const deleteItem = async (proceed) => {
    setDeleteOpen(false);
    if (!proceed) {
      setItemToDelete(null);
      return;
    }

    const id = itemToDelete;
    await deleteDoc(doc(db, 'userData', user.uid, 'presets', id));
    replaceAllPresets(data.filter((item, index) => item.id != id));
    setData(data.filter((item, index) => item.id != id));
    setItemToDelete(null);
  };

  const renameItem = async (name) => {
    setNameOpen(false);

    if (!name || !itemToRename) {
      setItemToRename(null);
      return;
    }

    const index = data.findIndex((item) => item.id == itemToRename);

    const ndata = [...data];
    ndata[index].name = name;

    const ref = doc(db, 'userData', user.uid, 'presets', ndata[index].id);
    await updateDoc(ref, ndata[index]);

    setData(data);
    replaceAllPresets([...data]);
    setItemToRename(null);
  };

  console.log(data, itemToDelete, itemToRename);
  return (
    <div>
      <AlertDialog
        op={deleteOpen}
        setResult={deleteItem}
        name={itemToDelete ? data.find((item) => item.id == itemToDelete).name : ''}
      ></AlertDialog>

      <NameDialog
        op={nameOpen}
        setName={renameItem}
        dName={itemToDelete ? data.find((item) => item.id == itemToDelete).name : ''}
      />
      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />

      <FlexBox style={{ marginTop: '25px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>My Presets</H2>
      </FlexBox>
      <FlexBox>
        <Box
          sx={{ padding: '10px 20px' }}
          md={{ padding: '15px 25px' }}
          style={{ maxWidth: '1500px' }}
        >
          <TableContainer>
            <FlexBox style={{ justifyContent: 'right' }}>
              <Searchbar setSearch={setSearch} />
            </FlexBox>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="25%">
                    <b>Name</b>
                  </TableCell>
                  <TableCell align="center" width="50%%">
                    <b>Preset</b>
                  </TableCell>
                  <TableCell align="center" width="25%">
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!filteredData && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}

                {filteredData && filteredData.length == 0 && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <div
                        style={{
                          width: '100%',
                          height: '50px',
                          marginTop: '20px',
                          textAlign: 'center'
                        }}
                      >
                        <h3>No Presets found</h3>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {filteredData &&
                  filteredData.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.preset}</TableCell>
                      <TableCell>
                        <ButtonPanel
                          disabled={index >= user.plan.saas.save_prompts}
                          id={item.id}
                          deleteItem={setItemToDelete}
                          rename={setItemToRename}
                          delLoading={itemToDelete == item.id}
                          renameLoading={itemToRename == item.id}
                        ></ButtonPanel>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </FlexBox>
    </div>
  );
};

export default SavedPresets;
