import { atom } from 'recoil';
import { defaultExifValue } from '../exif/AddExifDialog';

export const exifSelectionAtom = atom({
  key: 'exifSelection',
  default: {
    open: false,
    exif: {
      ...defaultExifValue
    },
    nextCallback: () => {},
    exifType: 'none'
  }
});
