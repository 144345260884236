import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  styled
} from '@mui/material';

import { H2 } from 'app/components/Typography';

import Loading from 'app/components/MatxLoading';
import { useState } from 'react';
import { useEffect } from 'react';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

export default function Invoices() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDoc(doc(getFirestore(), 'invoices', getAuth().currentUser.uid));

      if (!response.exists()) {
        setData([]);
        return;
      }
      let data = response.data().invoices;
      //reverse the array so that the latest invoice is on top
      data = data.reverse();
      setData(data);
    };

    fetchData();
  }, []);

  console.log(data);

  return (
    <div>
      <FlexBox style={{ marginTop: '25px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>Invoices</H2>
      </FlexBox>
      <FlexBox>
        <Box
          sx={{ padding: '10px 20px' }}
          md={{ padding: '15px 25px' }}
          style={{ maxWidth: '1000px' }}
        >
          <TableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="20%">
                    <b>Date</b>
                  </TableCell>

                  <TableCell align="center" width="20%">
                    <b>Name</b>
                  </TableCell>

                  <TableCell align="center" width="35%">
                    <b>Address</b>
                  </TableCell>
                  <TableCell align="center" width="10%%">
                    <b>Invoice</b>
                  </TableCell>
                  <TableCell align="center" width="15%">
                    <b>Status</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!data && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}

                {data && data.length == 0 && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <div
                        style={{
                          width: '100%',
                          height: '50px',
                          marginTop: '20px',
                          textAlign: 'center'
                        }}
                      >
                        <h3>No invoices found</h3>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {data &&
                  data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {new Date(item.time).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </TableCell>

                      <TableCell align="center">{item.name}</TableCell>

                      <TableCell align="center">{item.address}</TableCell>

                      <TableCell align="center">
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <u>open</u>
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <label
                          style={{
                            color: 'white',
                            backgroundColor:
                              item.type === 'paid'
                                ? 'green'
                                : item.type === 'failed'
                                ? 'red'
                                : 'orange',
                            fontWeight: 'bold',
                            padding: '5px 10px',
                            borderRadius: '5px'
                          }}
                        >
                          {item.type.toLocaleUpperCase()}
                        </label>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </FlexBox>
    </div>
  );
}
