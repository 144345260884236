// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager
} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAcT3O7fOfhrqdnqhQXhrF6Pgr5uiOozFw',
  authDomain: 'alphonseprompts.firebaseapp.com',
  projectId: 'alphonseprompts',
  storageBucket: 'alphonseprompts.appspot.com',
  messagingSenderId: '722242177478',
  appId: '1:722242177478:web:3da482b13c8bf8245b8ac7',
  measurementId: 'G-73PKFWK1L4'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
initializeFirestore(app, {
  localCache: persistentLocalCache(/*settings*/ { tabManager: persistentMultipleTabManager() })
});

export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
