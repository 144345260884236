import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, styled } from '@mui/material';
import { H1 } from 'app/components/Typography';
import { set } from 'lodash';
import { useState } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../../firebase';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 320,
  flexDirection: 'column',
  justifyContent: 'center'
}));

const IMG = styled('img')(() => ({
  width: '40%',
  maxWidth: '320px',
  alignSelf: 'center'
}));

const Center = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
  paddingBottom: '25vh',
  boxSizing: 'border-box'
}));

const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null); //null: not sent, 1: sent, 2: error

  const sendVerificationEmail = () => {
    setLoading(true);
    setState(null);

    sendEmailVerification(auth.currentUser)
      .then(() => {
        setState(1);
        setLoading(false);
      })
      .catch((error) => {
        setState(2);
        setLoading(false);
      });
  };

  return (
    <Center>
      <IMG src="/assets/images/verify_email.png" alt="" />

      <H1>Your account is not verified</H1>

      <Alert severity="info">
        If you had already sent verification email previously, please check your inbox.
      </Alert>
      <br></br>
      <div>
        <LoadingButton
          loading={loading}
          onClick={sendVerificationEmail}
          disabled={state === 1}
          fullWidth
          variant="contained"
          color="primary"
        >
          Send Verification Email
        </LoadingButton>
      </div>

      {state && (
        <div>
          <br></br>
          <Alert severity={state === 1 ? 'success' : 'error'}>
            {state === 1
              ? 'Verification email sent successfully. Please check your inbox.'
              : 'Failed to send verification email. Please try again later.'}
          </Alert>
        </div>
      )}
    </Center>
  );
};

export default VerifyEmail;
