import { Upload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  useMediaQuery
} from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import { useState } from 'react';
import { useRef } from 'react';
import app, { auth } from '../../../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { useTheme } from '@emotion/react';

export const AccountProfile = () => {
  const inputFile = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useAuth();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const upload = async (file) => {
    if (!file) {
      return;
    }
    setIsUploading(true);

    //upload to fire storage
    const storage = getStorage(app);
    const storageRef = ref(storage, user.uid + '/profile.jpg');
    const uploadTask = await uploadBytesResumable(storageRef, file);

    //get download url
    const downloadURL = await getDownloadURL(uploadTask.ref);

    await updateProfile(auth.currentUser, { photoURL: downloadURL });

    //update user profile

    setIsUploading(false);
  };

  return (
    <Card style={{ margin: '10px' }}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={auth.currentUser.photoURL}
            sx={{
              height: isMd ? 120 : 180,
              mb: 2,
              width: isMd ? 120 : 180
            }}
          />
          <Typography gutterBottom variant="h5">
            {auth.currentUser.displayName}
          </Typography>
          <Typography gutterBottom variant="h6">
            {auth.currentUser.email}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          accept=".jpg, .png, .jpeg"
          onChange={(e) => upload(e.target.files[0])}
        />
        <LoadingButton
          loading={isUploading}
          fullWidth
          variant="text"
          onClick={() => inputFile.current.click()}
        >
          Upload picture
        </LoadingButton>
      </CardActions>
    </Card>
  );
};
