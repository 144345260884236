import { Box, Container, Stack, Typography, Grid } from '@mui/material';

import { AccountProfile } from './account-profile';
import { AccountProfileDetails } from './account-profile-details';
import { Padding } from '@mui/icons-material';

const Page = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '1200px'
    }}
  >
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      justifyContent={{ xs: 'center' }}
    >
      <Grid item xs={11} md={4}>
        <AccountProfile />
      </Grid>
      <Grid item xs={11} md={7}>
        <AccountProfileDetails />
      </Grid>
    </Grid>
  </div>
);

export default Page;
