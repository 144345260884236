import {
  Box,
  Button,
  Grid,
  Icon,
  Select,
  TextField,
  styled,
  MenuItem,
  Snackbar,
  Alert
} from '@mui/material';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { H2 } from 'app/components/Typography';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useState } from 'react';
import InputTabs from './inputTabs';
import { useEffect } from 'react';
import useSettings from 'app/hooks/useSettings';
import updatePrompt from './promptGenerator';
import { LoadingButton, TabPanel } from '@mui/lab';
import NameDialog from './NameDialog';
import { collection, doc, addDoc, updateDoc, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import UpgradeDialog from '../UpgradePlanDialog';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CredentialChooserDialog from '../credential/CredentialChooser';
import { generate } from 'shortid';
import { getFunctions, httpsCallable } from 'firebase/functions';

const FlexBox = styled(Box)(() => ({
  display: 'flex'
}));

const FlexBoxVeritcal = styled(FlexBox)(() => ({
  flexDirection: 'column',
  padding: '12px 15px',
  maxWidth: '1200px'
}));

const HalfCard = styled(Box)(() => ({
  padding: '12px 10px',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px'
}));
const initialValues = {
  prompt: '',
  name: '',
  id: '',
  data: { url: '', subject: '', custom: '' },
  active: 0
};

const Generator = () => {
  const { settings, replaceGenPanel, replaceAllPrompts } = useSettings();
  const { user } = useAuth();
  const [values, setValues] = useState({ ...initialValues });
  const [msg, setMsg] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [allPrompts, setAllPrompts] = useState(settings.prompts);
  const [planMsg, setPlanMsg] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [openCredChooser, setOpenCredChooser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let p = updatePrompt(values, user.plan);
    replaceGenPanel({ ...values, prompt: p });
    setPrompt(p);
  }, [values]);

  useEffect(() => {
    if (settings.genPanel) {
      setValues({ ...settings.genPanel });
      setPrompt(settings.genPanel.prompt);
    }
  }, []);

  useEffect(() => {
    if (allPrompts) return;

    const getPrompts = async () => {
      const ref = collection(db, 'userData', user.uid, 'prompts');
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      setAllPrompts(nPrompts);
      replaceAllPrompts(nPrompts);
    };

    getPrompts();
  }, []);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const generateHandler = async () => {
    if (!user.plan.saas.generator) return;

    setIsGenerating(true);
    const prom = prompt.trim();

    if (!prom) {
      setMsg({ msg: 'No prompt to generate', sv: 'warning' });
      setIsGenerating(false);
      return;
    }

    try {
      const fn = httpsCallable(getFunctions(), 'generateImage');
      const { data } = await fn({ prompt: prom, promptID: values.id });
      if (data.error) {
        console.log({ data });
        if (data.error?.includes('Rate limit')) {
          setPlanMsg('You have reached your daily limit. Please upgrade your plan to continue.');
          setIsGenerating(false);
          return;
        } else setMsg({ msg: data.error, sv: 'error' });
        setIsGenerating(false);
        return;
      }
      navigate('/track/' + data.trackID);
    } catch (err) {
      const data = err;
      console.log({ data });
      setMsg({ msg: 'Something went wrong. Please try again later', sv: 'error' });
    } finally {
      setIsGenerating(false);
    }
  };

  const saveHandler = async (name) => {
    setOpenDialog(false);
    if (user.plan.saas.save_prompts <= allPrompts.length) return;

    if (!name) {
      setIsSaving(false);
      return;
    }

    let tmp = { ...values, name: name, prompt: prompt };
    let nval = cloneDeep(tmp);

    const ref = collection(db, 'userData', user.uid, 'prompts');
    const doc = await addDoc(ref, nval);
    nval.id = doc.id;

    if (settings.prompts) {
      let nPrompts = [...settings.prompts];
      nPrompts.push(nval);

      replaceAllPrompts(nPrompts);
      setAllPrompts(nPrompts);
    } else {
      replaceAllPrompts([nval]);
      setAllPrompts([nval]);
    }

    setValues(nval);
    setIsSaving(false);
    setMsg({ msg: 'Saved' });
  };

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  return (
    <FlexBoxVeritcal>
      <Snackbar
        open={!!msg}
        autoHideDuration={3000}
        onClose={() => setMsg(null)}
        sx={{ height: '15%' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert sx={{ m: 1 }} severity={msg?.sv} variant="filled">
          {msg?.msg}
        </Alert>
      </Snackbar>

      <UpgradeDialog msg={planMsg} setMsg={setPlanMsg} />
      <CredentialChooserDialog
        open={openCredChooser}
        cancel={() => setOpenCredChooser(false)}
        proceed={() => {
          setOpenCredChooser(false);
          generateHandler();
        }}
      />

      <NameDialog setName={saveHandler} op={openDialog} dName={values.name}></NameDialog>
      <H2>Generator</H2>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={matches ? { marginTop: '10px' } : { marginTop: '15px', marginLeft: '20px' }}
        justifyContent={{ xs: 'center', md: 'left' }}
      >
        <Grid item xs={11} md={5}>
          <TextField
            variant="outlined"
            label="url"
            style={{ width: '100%' }}
            value={values.data.url}
            onChange={(e) => {
              let nval = { ...values };
              nval.data.url = e.target.value;

              setValues(nval);
            }}
          ></TextField>
        </Grid>
        <Grid item xs={11} md={5}>
          <TextField
            variant="outlined"
            label="subject"
            style={{ width: '100%' }}
            value={values.data.subject}
            onChange={(e) => {
              let nval = { ...values };
              nval.data.subject = e.target.value;

              setValues(nval);
            }}
          ></TextField>
        </Grid>

        <Grid item xs={11} md={7}>
          <TextField
            value={values.data.custom}
            onChange={(e) => {
              let nval = { ...values };
              nval.data.custom = e.target.value;

              setValues(nval);
            }}
            variant="outlined"
            label="Custom Parameter"
            style={{ width: '100%' }}
          ></TextField>
        </Grid>

        <Grid item xs={11} md={3}>
          <Select
            disabled={isSaving || !allPrompts}
            style={{ width: '100%' }}
            value={values.id}
            onChange={(e) => {
              let nval = { ...values };
              let index = allPrompts.findIndex((p) => p.id === e.target.value);
              if (index === -1 || e.target.value === '') {
                replaceGenPanel(null);
                setPrompt('');
                setValues({ ...initialValues, data: { ...initialValues.data } });
              } else {
                nval = allPrompts[index];
                replaceGenPanel(null);
                setValues({ ...nval });
              }
            }}
          >
            <MenuItem value="">--New Prompt--</MenuItem>

            {allPrompts &&
              allPrompts.map((p, i) => (
                <MenuItem disabled={i >= user.plan.saas.save_prompts} key={p.rKey} value={p.id}>
                  {p.name}
                </MenuItem>
              ))}
          </Select>
        </Grid>

        <Grid item xs={11} md={7}>
          <TextField
            value={prompt}
            multiline
            variant="outlined"
            onChange={(e) => setPrompt(e.target.value)}
            minRows={5}
            style={{ width: '100%' }}
          ></TextField>
        </Grid>

        <Grid item xs={11} md={3}>
          <FlexBox
            style={{
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '90%',
              gap: '10px'
            }}
          >
            <FlexBox style={{ gap: '12px' }}>
              <LoadingButton
                disabled={!user.plan.saas.generator}
                loading={isSaving}
                variant="contained"
                style={{ width: '80%', background: 'gray' }}
                onClick={() => {
                  if (user.plan.saas.save_prompts <= allPrompts.length) {
                    setPlanMsg(
                      'You can save upto ' +
                        user.plan.saas.save_prompts +
                        ' prompts. You must delete some saved prompts to make room for the new ones.'
                    );
                    return;
                  }

                  setIsSaving(true);
                  setOpenDialog(true);
                }}
              >
                Save
              </LoadingButton>
              <Button
                disabled={!user.plan.saas.generator}
                variant="contained"
                style={{ width: '20%', background: 'red' }}
                onClick={() => {
                  replaceGenPanel(null);
                  setPrompt('');
                  setValues({ ...initialValues, data: { ...initialValues.data } });
                }}
              >
                <Icon> clear</Icon>
              </Button>
            </FlexBox>

            <Button
              disabled={!user.plan.saas.generator}
              color="secondary"
              variant="contained"
              style={{ width: '100%' }}
              onClick={() => {
                navigator.clipboard.writeText(prompt);
                setMsg({ msg: 'Copied' });
              }}
            >
              Copy
            </Button>

            <LoadingButton
              loading={isGenerating}
              disabled={!user.plan.saas.generator}
              variant="contained"
              style={{ width: '100%' }}
              onClick={() => {
                const should_open = window.localStorage.getItem('showChooseCred');
                if (should_open !== 'false') setOpenCredChooser(true);
                else generateHandler();
              }}
            >
              Generate
            </LoadingButton>
          </FlexBox>
        </Grid>

        {/* Dropdown tabs */}

        <Grid item xs={11} md={10} style={{ marginTop: '10px' }}>
          <HalfCard>
            <InputTabs values={values} setValues={setValues} data={settings.panels} />
          </HalfCard>
        </Grid>
      </Grid>
    </FlexBoxVeritcal>
  );
};

export default Generator;
