import {
  Box,
  Button,
  Fab,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery
} from '@mui/material';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { H2 } from 'app/components/Typography';
import { is } from 'date-fns/locale';
import { useState } from 'react';
import PricingCard from './PricingCard';
import Loading from 'app/components/MatxLoading';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useEffect } from 'react';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

const MToggleButton = styled(ToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#3f50b5'
  }
});

const Pricing = () => {
  const { user } = useAuth();
  const [isMonthly, setIsMonthly] = useState(false);
  const [pacakges, setPackages] = useState(null);

  useEffect(() => {
    const getPackages = async () => {
      const resp = await getDoc(doc(getFirestore(), 'frontendData', 'pricing'));

      const data = resp.data();
      const _packages = [];
      Object.keys(data).forEach((key) => {
        _packages.push({ ...data[key], name: key });
      });

      _packages.sort((a, b) => a.order - b.order);

      setPackages(_packages);
    };
    getPackages();
  }, []);
  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  if (!pacakges) {
    return (
      <FlexBox>
        <Loading />
      </FlexBox>
    );
  }

  return (
    <div>
      <Box
        m={{
          xs: 3
        }}
      >
        <FlexBox>
          {/* <ToggleButtonGroup size="small">
            <MToggleButton
              selected={isMonthly}
              value="center"
              color="primary"
              onClick={() => setIsMonthly(true)}
              sx={{ borderRadius: '12px 0 0 12px', width: '100px' }}
            >
              Monthly
            </MToggleButton>
            <MToggleButton
              selected={!isMonthly}
              value="center"
              color="primary"
              onClick={() => setIsMonthly(false)}
              sx={{ borderRadius: '0 12px 12px 0', width: '100px' }}
            >
              Annual
            </MToggleButton>
          </ToggleButtonGroup> */}
        </FlexBox>
      </Box>

      <Box
        m={{
          md: 3
        }}
      >
        <Grid
          container
          rowSpacing={2}
          justifyContent={{ xs: 'center' }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {pacakges.map((pc, index) => (
            <Grid item xs={12} md={4} lg={3} key={index}>
              <PricingCard price={pc} isMonthly={isMonthly} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Pricing;
