import { Link } from 'react-router-dom';

const {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  Checkbox,
  Button,
  Box,
  DialogActions,
  FormControl,
  FormControlLabel,
  Autocomplete,
  TextField
} = require('@mui/material');
const { default: useAuth } = require('app/hooks/useAuth');
const { getDocs, getFirestore, collection } = require('firebase/firestore');
const { useEffect, useState } = require('react');

const UploadToWordpressDialog = ({
  open,
  setOpen,
  next,
  images,
  sites,
  setSelectedImages,
  selectedImages,
  loadingSites
}) => {
  const [selectedSites, setSelectedSites] = useState([]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Select Images To Upload</Typography>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {images.map((img, idx) => (
              <Box
                key={idx}
                m={1}
                boxSizing={'border-box'}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                border={selectedImages.includes(img) ? '2px solid #3f51b5' : '2px solid #fff'}
                onClick={(e) => {
                  if (selectedImages.includes(img)) {
                    setSelectedImages(selectedImages.filter((i) => i !== img));
                  } else setSelectedImages([...selectedImages, img]);
                }}
              >
                <img src={img} style={{ width: '100px', height: '100px', margin: '4px' }} />
              </Box>
            ))}
          </Box>
        </Box>
        <br></br>

        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Select WordPress Sites</Typography>

          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            mt={1}
            maxHeight="250px"
            overflow="scroll"
          >
            {sites && sites.length === 0 && !loadingSites && (
              <Typography variant="body1">
                No sites found.
                <Link to="/wordpress_sites/choose" color="blue">
                  {' '}
                  Add a site{' '}
                </Link>
                to upload images.
              </Typography>
            )}
            {sites && (
              <Autocomplete
                style={{ marginTop: '10px' }}
                multiple
                fullWidth
                id="tags-outlined"
                options={sites}
                getOptionLabel={(option) =>
                  option.url.replace('https://', '').replace('/wp-json', '')
                }
                filterSelectedOptions
                onChange={(e, value) => setSelectedSites(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Sites"
                    placeholder="Select Sites"
                  />
                )}
              />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          disabled={selectedImages.length === 0 || selectedSites.length === 0}
          onClick={() => next(selectedImages, selectedSites)}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadToWordpressDialog;
