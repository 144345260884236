import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VerifyEmail from '../VerifyEmail';
import useAuth from 'app/hooks/useAuth';
import { use } from 'echarts';
import { useEffect, useState } from 'react';
import { MatxSearchBox } from 'app/components';
import './search.css';
import useSettings from 'app/hooks/useSettings';
import { blue, red } from '@mui/material/colors';
import { H2 } from 'app/components/Typography';
import { LoadingButton } from '@mui/lab';
import Loading from 'app/components/MatxLoading';
import { db } from '../../../firebase';
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  getFirestore
} from 'firebase/firestore';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import exifAtom from './exifAtom';
import AddExifDialog from './AddExifDialog';
import { toast } from 'react-toastify';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}));

const MyEXIFS = () => {
  const [exifData, setExifData] = useRecoilState(exifAtom);
  const [openAddExif, setOpenAddExif] = useState(false);
  const [editing, setEditing] = useState(-1);
  const [deleting, setDeleting] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const exifs = [];
      const querySnapshot = await getDocs(
        collection(getFirestore(), 'userData', user.uid, 'exifs')
      );
      querySnapshot.forEach((doc) => {
        exifs.push({ id: doc.id, ...doc.data() });
      });
      setExifData(exifs);
    };

    if (!exifData) fetchData();
  }, [exifData]);

  const deleteExif = async (index) => {
    setDeleting(index);
    try {
      await deleteDoc(doc(getFirestore(), 'userData', user.uid, 'exifs', exifData[index].id));
      setExifData(exifData.filter((item, i) => i !== index));
    } catch (e) {
      console.error(e);
      toast.error('Failed to delete Exif data');
    }
    setDeleting(-1);
  };

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  return (
    <div>
      <AddExifDialog
        open={openAddExif}
        setOpen={setOpenAddExif}
        editing={editing}
        setEditing={setEditing}
      />
      <FlexBox style={{ marginTop: '25px' }}>
        <H2 style={{ position: 'absolute', top: '-15px', left: '12px' }}>My EXIFs</H2>
      </FlexBox>
      <FlexBox>
        <Box
          sx={{ padding: '10px 20px' }}
          md={{ padding: '15px 25px' }}
          style={{ maxWidth: '500px' }}
        >
          <TableContainer>
            <FlexBox style={{ justifyContent: 'right' }}>
              <Button startIcon={<Add />} color="primary" onClick={() => setOpenAddExif(true)}>
                Add New
              </Button>
            </FlexBox>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="70%">
                    <b>Layout Name</b>
                  </TableCell>
                  <TableCell align="right" width="30%">
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!exifData && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}

                {exifData && exifData.length == 0 && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={3}>
                      <div
                        style={{
                          width: '100%',
                          height: '50px',
                          marginTop: '20px',
                          textAlign: 'center'
                        }}
                      >
                        <h3>No EXIF found</h3>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {exifData &&
                  exifData.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="right">
                        <LoadingButton
                          loading={deleting === index}
                          color="error"
                          onClick={() => {
                            deleteExif(index);
                          }}
                        >
                          <Delete />
                        </LoadingButton>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setEditing(index);
                            setOpenAddExif(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </FlexBox>
    </div>
  );
};

export default MyEXIFS;
