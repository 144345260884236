import shortid from 'shortid';
import { db } from '../../../firebase';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';

const data = [
  {
    name: 'settings',
    icon: '',
    fields: [
      {
        name: 'Style',
        icon: '',
        id: 'style',
        options: [
          {
            text: '--Select Style--',
            value: '$'
          },
          {
            text: 'Anime character',
            value: 'Anime character'
          },
          {
            text: 'Cartoon',
            value: 'Cartoon book'
          },
          {
            text: 'Cartoon draw',
            value: 'cartoon draw'
          },
          {
            text: 'Cartoon character',
            value: 'cartoon character'
          },
          {
            text: 'Caricature',
            value: 'caricature style, big head small full body'
          },
          {
            text: 'Comics book',
            value: 'Comics book'
          },
          {
            text: 'Comics Anime',
            value: 'Comics Anime'
          },
          {
            text: 'Icon',
            value: 'Icon'
          },
          {
            text: 'Blueprint',
            value: 'blueprint'
          },
          {
            text: 'Illustration',
            value: 'Illustration'
          },
          {
            text: 'Isometric',
            value: 'Isometric clean pixel art image'
          },
          {
            text: 'Paint',
            value: 'Paint'
          },
          {
            text: 'Vector flat',
            value: 'Vector flat'
          },
          {
            text: 'Minimalist Flat design',
            value: 'Minimalist, flat design, poster'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Format',
        icon: '',
        id: 'formats',
        options: [
          {
            text: '--Select Format--',
            value: '$'
          },
          {
            text: '1:1',
            value: '--ar 1:1'
          },
          {
            name: 'Horizontal',
            options: [
              {
                text: '3:2',
                value: '--ar 3:2'
              },
              {
                text: '16:9',
                value: '--ar 16:9'
              },
              {
                text: '5:1',
                value: '--ar 5:1'
              },
              {
                text: '3:1',
                value: '--ar 3:1'
              },
              {
                text: '21:9',
                value: '--ar 21:9'
              }
            ]
          },
          {
            name: 'Vertical',
            options: [
              {
                text: '2:3',
                value: '--ar 2:3'
              },
              {
                text: '9:16',
                value: '--ar 9:16'
              },
              {
                text: '1:5',
                value: '--ar 1:5'
              },
              {
                text: '1:3',
                value: '--ar 1:3'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Quality',
        icon: '',
        id: 'quality',
        options: [
          {
            text: '--Select quality--',
            value: '$'
          },
          {
            text: '2K',
            value: '2K'
          },
          {
            text: '4K',
            value: '4K'
          },
          {
            text: '8K',
            value: '8K'
          },
          {
            text: '16k',
            value: '16k'
          },
          {
            text: '2D',
            value: '2D'
          },
          {
            text: '3D',
            value: '3D'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Negativ',
        icon: '',
        id: 'nega',
        tag: 'input',
        type: 'text',
        placeholder: 'Negativ'
      },
      {
        name: 'Version',
        icon: '',
        id: 'version',
        options: [
          {
            text: '--Select Version--',
            value: '$'
          },
          {
            text: 'v 5.1 raw',
            value: '--v 5.1 --style raw'
          },
          {
            text: 'Raw',
            value: '--style raw'
          },
          {
            text: 'Niji 5',
            value: '--niji 5'
          },
          {
            text: 'Niji 4',
            value: '--niji 4'
          },
          {
            text: 'v 5.1',
            value: '--v 5.1'
          },
          {
            text: 'v 5',
            value: '--v 5'
          },
          {
            text: 'v 4',
            value: '--v 4'
          },
          {
            text: 'v 3',
            value: '--v 3'
          }
        ],
        tag: 'select'
      }
    ]
  },
  {
    name: '',
    icon: 'settings_applications',
    fields: [
      {
        name: 'Stylise',
        icon: '',
        id: 't2a',
        options: [
          {
            text: '--Select Stylise--',
            value: '$'
          },
          {
            text: '--s 0',
            value: '--s 0'
          },
          {
            text: '--s 250',
            value: '--s 250'
          },
          {
            text: '--s 500',
            value: '--s 500'
          },
          {
            text: '--s 750',
            value: '--s 750'
          },
          {
            text: '--s 1000',
            value: '--s 1000'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Quality',
        icon: '',
        id: 't2b',
        options: [
          {
            text: '--Select Quality--',
            value: '$'
          },
          {
            text: '--q .25',
            value: '--q .25'
          },
          {
            text: '--q .5',
            value: '--q .5'
          },
          {
            text: '--q 1',
            value: '--q 1'
          },
          {
            text: '--q 2',
            value: '--q 2'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Chaos',
        icon: '',
        id: 't2c',
        options: [
          {
            text: '--Select Chaos--',
            value: '$'
          },
          {
            text: '--c 0',
            value: '--c 0'
          },
          {
            text: '--c 20',
            value: '--c 20'
          },
          {
            text: '--c 50',
            value: '--c 50'
          },
          {
            text: '--c 100',
            value: '--c 100'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Divider',
        icon: '',
        id: 'Divider',
        options: [
          {
            text: ' , ',
            value: ', '
          },
          {
            text: ' ::x ',
            value: '::5 '
          }
        ],
        tag: 'select'
      },
      {
        name: 'Seed',
        icon: '',
        id: 'seed',
        tag: 'input',
        type: 'text',
        placeholder: 'Seed'
      },
      {
        name: 'Image Weight',
        icon: '',
        id: 'Weight',
        options: [
          {
            text: '--Select Weight--',
            value: '$'
          },
          {
            text: '--iw 0.5',
            value: '--iw 0.5'
          },
          {
            text: '--iw 1',
            value: '--iw 1'
          },
          {
            text: '--iw 2',
            value: '--iw 2'
          }
        ],
        tag: 'select'
      }
    ]
  },
  {
    name: 'photo',
    icon: '',
    fields: [
      {
        name: 'Type',
        icon: '',
        id: 't3a',
        options: [
          {
            text: '--Select Type--',
            value: '$'
          },
          {
            text: 'Film still',
            value: 'film still'
          },
          {
            text: 'Professional shot',
            value: 'professional shot'
          },
          {
            name: 'Famous photo',
            options: [
              {
                text: 'Studio photography',
                value: 'Studio photography of'
              },
              {
                text: 'Corporate photography',
                value: 'corporate photography of'
              },
              {
                text: 'Editorial Photography',
                value: 'editorial Photography of'
              },
              {
                text: 'Portrait photography',
                value: 'portrait photography of'
              },
              {
                text: 'Headshot portrait',
                value: 'headshot portrait of'
              },
              {
                text: 'Close up photography',
                value: 'Close up photography of'
              },
              {
                text: 'Street photography',
                value: 'street photography of'
              },
              {
                text: 'Food photography',
                value: 'Realestic food photography of'
              }
            ]
          },
          {
            name: 'Editorial photo',
            options: [
              {
                text: 'Culinary photography',
                value: 'Culinary photography of'
              },
              {
                text: 'Minimalist Photography',
                value: 'minimalist Photography of'
              },
              {
                text: 'Landscape photography',
                value: 'landscape photography of'
              },
              {
                text: 'Boudoir photography',
                value: 'Boudoir photography of'
              },
              {
                text: 'Fashion Photography',
                value: 'Fashion Photography of'
              },
              {
                text: 'Sports Photography',
                value: 'Sports Photography of'
              },
              {
                text: 'Architectural Photography',
                value: 'Architectural Photography of'
              },
              {
                text: 'Product Photography',
                value: 'Product Photography of'
              }
            ]
          },
          {
            name: 'Art photo',
            options: [
              {
                text: 'Cinematic photography',
                value: 'cinematic photography of'
              },
              {
                text: 'Urbex photography',
                value: 'Urban Exploration Photography of'
              },
              {
                text: 'Fisheye photography',
                value: 'Fisheye Photography of'
              },
              {
                text: 'POV photo',
                value: 'POV, hyper-realistic GoPro selfie of'
              },
              {
                text: 'Selfie photo',
                value: 'Create an inviting first-person selfie'
              },
              {
                text: 'Macro shot',
                value: 'macro shot of'
              },
              {
                text: 'Time-Lapse Photography',
                value: 'Time-Lapse Photography of'
              },
              {
                text: 'Tilt-Shift Photography',
                value: 'Tilt-Shift Photography of'
              },
              {
                text: 'Fine Art Photography',
                value: 'Fine Art Photography,'
              },
              {
                text: 'Surreal Photography',
                value: 'Surreal Photography,'
              },
              {
                text: 'Sepia Photography',
                value: 'Sepia Photography,'
              },
              {
                text: 'Star Trails Photography',
                value: 'Star Trails Photography,'
              },
              {
                text: 'Low-key photography',
                value: 'Low-key photography of'
              },
              {
                text: 'High-key photography',
                value: 'High-key photography of'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Background',
        icon: '',
        id: 'backgroundphoto',
        options: [
          {
            text: '--Select Background--',
            value: '$'
          },
          {
            name: 'Natural background',
            options: [
              {
                text: 'Beach',
                value: 'on the beach'
              },
              {
                text: 'Pool',
                value: 'on the pool'
              },
              {
                text: 'Street',
                value: 'on the street'
              },
              {
                text: 'Road',
                value: 'on the road'
              },
              {
                text: 'Forest',
                value: 'in the forest'
              },
              {
                text: 'Undergrowth',
                value: 'in the undergrowth'
              }
            ]
          },
          {
            name: 'Studio background',
            options: [
              {
                text: 'White background',
                value: 'on studio white background'
              },
              {
                text: 'Black background',
                value: 'on studio black background'
              },
              {
                text: 'Grey background',
                value: 'on studio grey background'
              },
              {
                text: 'Light grey background',
                value: 'on studio light grey background'
              },
              {
                text: 'Pink background',
                value: 'on studio pink background'
              },
              {
                text: 'Blue background',
                value: 'on studio blue background'
              },
              {
                text: 'Muted color background',
                value: 'on studio muted color background'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Colors',
        icon: '',
        id: 'colorsphoto',
        options: [
          {
            text: '--Select colors--',
            value: '$'
          },
          {
            text: 'Black and white photography',
            value: 'black and white photography'
          },
          {
            text: 'Color photography',
            value: 'Color photography'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Angle view',
        icon: '',
        id: 't3d',
        options: [
          {
            text: '--Select Angle--',
            value: '$'
          },
          {
            name: 'Low view',
            options: [
              {
                text: 'Low-angle shot',
                value: 'low-angle shot'
              },
              {
                text: 'Extreme low-angle',
                value: 'extreme low-angle shot'
              }
            ]
          },
          {
            name: 'Medium shot',
            options: [
              {
                text: 'Side-Angle',
                value: 'side-Angle shot'
              },
              {
                text: 'Center view',
                value: 'center view'
              },
              {
                text: 'Dynamic angle',
                value: 'dynamic angle, mesmerizing and dynamic composition'
              }
            ]
          },
          {
            name: 'Landscape',
            options: [
              {
                text: 'Wide-Angle Shot',
                value: 'Wide-Angle Shot'
              },
              {
                text: 'Ultra-Wide Angle',
                value: 'Ultra-Wide Angle shot'
              },
              {
                text: 'Far-Shot',
                value: 'Far-Shot'
              }
            ]
          },
          {
            name: 'High view',
            options: [
              {
                text: 'Top-Down Shot',
                value: 'Top-Down Shot'
              },
              {
                text: 'Ground-Shot Angle',
                value: 'Ground-Shot Angle'
              },
              {
                text: 'Overhead shot',
                value: 'overhead shot'
              },
              {
                text: 'High-Angle',
                value: 'high-Angle shot'
              },
              {
                text: 'Extreme High-Angle',
                value: 'Extreme High-Angle shot'
              },
              {
                text: 'Birds-Eye-View',
                value: 'birds-Eye-View'
              },
              {
                text: 'Drone view',
                value: 'drone view'
              },
              {
                text: 'Airplane view',
                value: 'airplane view'
              },
              {
                text: 'Satellite-View',
                value: 'satellite-view'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Composition',
        icon: '',
        id: 't3e',
        options: [
          {
            text: '--Select Composition--',
            value: '$'
          },
          {
            name: 'Shot size',
            options: [
              {
                text: 'Extreme Close-up',
                value: 'extreme Close-up view'
              },
              {
                text: 'Close-up',
                value: 'close-up view'
              },
              {
                text: 'Medium close up',
                value: 'medium close up view'
              },
              {
                text: 'Medium Shot',
                value: 'medium view'
              },
              {
                text: 'Medium full-shot',
                value: 'Medium full-shot'
              },
              {
                text: 'Full body shot',
                value: 'full-body view'
              }
            ]
          },
          {
            name: 'Various',
            options: [
              {
                text: 'distant shot',
                value: 'distant shot'
              },
              {
                text: 'Asymmetric',
                value: 'asymmetric shot'
              },
              {
                text: 'Symmetric',
                value: 'symmetric shot'
              },
              {
                text: 'Side View',
                value: 'Side-View'
              },
              {
                text: 'Back view',
                value: 'Back view'
              },
              {
                text: 'Shot From Behind',
                value: 'Shot From Behind'
              },
              {
                text: 'Medium-Full Shot',
                value: 'Medium-Full Shot'
              },
              {
                text: 'Full-Body Shot',
                value: 'Full-Body Shot'
              },
              {
                text: 'Selfie',
                value: 'hyper - realistic selfie'
              },
              {
                text: 'Centered-Shot',
                value: 'centered-shot'
              },
              {
                text: 'First-Person',
                value: 'first-Person'
              },
              {
                text: 'Third-person',
                value: 'third-person'
              },
              {
                text: 'Golden ratio',
                value: 'Golden ratio'
              },
              {
                text: 'Rule of thirds',
                value: 'rule of thirds'
              },
              {
                text: 'Horizon Line',
                value: 'Horizon Line'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Fast Quality (test)',
        icon: '',
        id: 't3f',
        options: [
          {
            text: '--Select Quality--',
            value: '$'
          },
          {
            name: 'Filter specials',
            options: [
              {
                text: 'Film grain',
                value:
                  'film grain texture, image imperfections and dust, highly detailed, sharp focus'
              },
              {
                text: 'UV filter',
                value: 'UV filter, professional shot, highly detailed, sharp focus'
              },
              {
                text: 'ND filter',
                value: 'ND filter, professional shot, highly detailed, sharp focus'
              },
              {
                text: 'Image noise',
                value:
                  'professional shot, image noise, highly detailed, strong film grain, sharp focus'
              }
            ]
          },
          {
            name: 'Highly detail',
            options: [
              {
                text: 'Highly detailed',
                value:
                  'professional shot, highly detailed, film photography, artstation, sharp focus'
              },
              {
                text: 'Ultra detailed',
                value: 'professional shot, ultra detailed, octane render'
              },
              {
                text: 'Realistic photography',
                value: 'realistic photography, ultra detailed'
              },
              {
                text: 'Extremely detailed',
                value:
                  'professional shot, photorealistic, hyperrealistic, realistic, extremely detailed, sharp focus'
              }
            ]
          },
          {
            name: 'Improve lighting',
            options: [
              {
                text: 'Perfect lighting',
                value:
                  'perfect lighting to emphasize textures and colors, realestic photography, ultra detailed, sharp focus, professional shot'
              },
              {
                text: 'Bright light',
                value: 'Bright light, professional shot, highly detailed, sharp focus'
              },
              {
                text: 'Brightness',
                value:
                  'high brightness, over exposed photo, without shadows, professional shot, photorealistic, hyperrealistic, realistic, extremely detailed, sharp focus'
              }
            ]
          }
        ],
        tag: 'select'
      }
    ]
  },
  {
    name: '',
    icon: 'camera_alt',
    fields: [
      {
        name: 'Lens',
        icon: '',
        id: 'lens',
        options: [
          {
            text: '--Select Lens--',
            value: '$'
          },
          {
            text: '14mm',
            value: '14mm lens'
          },
          {
            text: '24mm',
            value: '24mm lens'
          },
          {
            text: '35mm',
            value: '35mm lens'
          },
          {
            text: '50mm',
            value: '50mm lens'
          },
          {
            text: '85mm',
            value: '85mm lens'
          },
          {
            text: '100mm',
            value: '100mm lens'
          },
          {
            text: '200mm',
            value: '200mm lens'
          }
        ],
        tag: 'select'
      },
      {
        name: 'ISO',
        icon: '',
        id: 't4b',
        options: [
          {
            text: '--Select ISO--',
            value: '$'
          },
          {
            text: 'ISO 100',
            value: 'ISO 100'
          },
          {
            text: 'ISO 200',
            value: 'ISO 200'
          },
          {
            text: 'ISO 400',
            value: 'ISO 400'
          },
          {
            text: 'ISO 800',
            value: 'ISO 800'
          },
          {
            text: 'ISO 3200',
            value: 'ISO 3200'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Photo film',
        icon: '',
        id: 't4c',
        options: [
          {
            text: '--Select Film--',
            value: '$'
          },
          {
            name: 'Colour',
            options: [
              {
                text: 'Kodak Portra 400',
                value: 'shot on Kodak Portra 400'
              },
              {
                text: 'Kodak Gold 200',
                value: 'shot on Kodak Gold 200'
              },
              {
                text: 'Kodak Ektar 100',
                value: 'shot on Kodak Ektar 100'
              },
              {
                text: 'Fujifilm Superia 400',
                value: 'shot on Fujifilm Superia 400'
              },
              {
                text: 'Fujifilm Pro 400H',
                value: 'shot on Fujifilm Pro 400H'
              },
              {
                text: 'CineStill 800T',
                value: 'shot on CineStill 800T'
              },
              {
                text: 'Svema 250 film',
                value: 'shot on Svema 250 film'
              },
              {
                text: 'Fujichrome Velvia 50',
                value: 'shot on Fujichrome Velvia 50'
              },
              {
                text: 'Agfa Vista 200',
                value: 'shot on Agfa Vista 200'
              }
            ]
          },
          {
            name: 'Black & White',
            options: [
              {
                text: 'Kodak Tri-X 400',
                value: 'shot on Kodak Tri-X 400, film grain'
              },
              {
                text: 'Fujifilm Neopan Acros 100',
                value: 'shot on Fujifilm Neopan Acros 100, film grain'
              },
              {
                text: 'Ilford XP2 400',
                value: 'shot on Ilford XP2 400, film grain'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Apertur',
        icon: '',
        id: 't4d',
        options: [
          {
            text: '--Select Aperture--',
            value: '$'
          },
          {
            text: 'Bokeh',
            value: 'Bokeh'
          },
          {
            text: 'Long exposure photo',
            value: 'long exposure photo'
          },
          {
            text: 'Tilt shift',
            value: 'Tilt shift'
          },
          {
            text: 'Low depth of field',
            value: 'low depth of field, low aperture'
          },
          {
            text: 'large depth of field',
            value: 'large depth of field, large aperture'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Camera',
        icon: '',
        id: 'camera',
        options: [
          {
            text: '--Select Camera--',
            value: '$'
          },
          {
            name: 'Film camera',
            options: [
              {
                text: 'Film camera',
                value: 'photo taken with film camera'
              },
              {
                text: 'ROLLEIFLEX 3.5E',
                value: 'photo taken with ROLLEIFLEX 3.5E PLANAR'
              },
              {
                text: 'Nikon F2',
                value: 'photo taken with Nikon F2'
              },
              {
                text: 'OLYMPUS OM10',
                value: 'photo taken with OLYMPUS OM10'
              },
              {
                text: 'CANON AE-1',
                value: 'photo taken with CANON AE-1'
              },
              {
                text: 'Leica M6',
                value: 'photo taken with Leica M6'
              }
            ]
          },
          {
            name: 'Digital camera',
            options: [
              {
                text: 'Sony a7R IV',
                value: 'photo taken with Sony a7R IV camera'
              },
              {
                text: 'Ricoh R1',
                value: 'photo taken with ricoh r1'
              },
              {
                text: 'Leica Q2',
                value: 'photo taken with LEICA Q2'
              },
              {
                text: 'Leica M10',
                value: 'photo taken with Leica M10'
              },
              {
                text: 'Light L16',
                value: 'photo taken with Light L16'
              },
              {
                text: 'Hasselblad X2D',
                value: 'photo taken with Hasselblad X2D 100C'
              },
              {
                text: 'Hasselblad X1D',
                value: 'photo taken with Hasselblad X1D'
              },
              {
                text: 'Canon EOS R5',
                value: 'photo taken with Canon EOS R5'
              },
              {
                text: 'Canon 5D Mark IV',
                value: 'photo taken with Canon 5D Mark IV'
              },
              {
                text: 'Nikon Z7 II',
                value: 'photo taken with Nikon Z7 II'
              },
              {
                text: 'Nikon d850',
                value: 'photo taken with Nikon d850'
              },
              {
                text: 'Fujifilm GFX 50R',
                value: 'photo taken with Fujifilm GFX 50R'
              },
              {
                text: 'Phase One XF IQ4',
                value: 'photo taken with Phase One XF IQ4'
              },
              {
                text: ' DSLR camera',
                value: 'photo taken with DSLR camera'
              }
            ]
          },
          {
            name: 'Smartphone',
            options: [
              {
                text: 'Samsung Galaxy',
                value: 'photo taken with Samsung Galaxy S23 Ultra'
              },
              {
                text: 'Apple iPhone',
                value: 'photo taken with Apple iPhone 14 Pro Max'
              },
              {
                text: 'GoPro',
                value: 'photo taken with GoPro'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Photographer',
        icon: '',
        id: 't4f',
        options: [
          {
            text: '--Select photographer--',
            value: '$'
          },
          {
            name: 'Studio & Mode',
            options: [
              {
                text: 'Martin Schoeller',
                value: 'by Martin Schoeller'
              },
              {
                text: 'Terry Richardson',
                value: 'by Terry Richardson'
              },
              {
                text: 'Peter Lindbergh',
                value: 'by Peter Lindbergh'
              },
              {
                text: 'David Hamilton',
                value: 'by David Hamilton'
              },
              {
                text: 'Joel Grimes',
                value: 'by Joel Grimes'
              },
              {
                text: 'Bill Gekas',
                value: 'by Bill Gekas'
              },
              {
                text: 'Horst',
                value: 'by Horst'
              },
              {
                text: 'Annie Leibovitz',
                value: 'by Annie Leibovitz'
              },
              {
                text: 'Richard Avedon',
                value: 'by Richard Avedon'
              },
              {
                text: 'David LaChapelle',
                value: 'by David LaChapelle'
              }
            ]
          },
          {
            name: 'Street',
            options: [
              {
                text: 'Steve McCurry',
                value: 'by Steve McCurry'
              },
              {
                text: 'Dorothea Lange',
                value: 'by Dorothea Lange'
              },
              {
                text: 'Robert Doisneau',
                value: 'by Robert Doisneau'
              },
              {
                text: 'Gregory Crewdson',
                value: 'by Gregory Crewdson'
              },
              {
                text: 'Bill Cunningham',
                value: 'by Bill Cunningham'
              },
              {
                text: 'Frank Horvat',
                value: 'by Frank Horvat'
              },
              {
                text: 'Helmut Newton',
                value: 'by Helmut Newton'
              },
              {
                text: 'Henri Cartier-Bresson',
                value: 'by Henri Cartier-Bresson'
              },
              {
                text: 'Martin Parr',
                value: 'by Martin Parr'
              },
              {
                text: 'Saul Leiter',
                value: 'by Saul Leiter'
              }
            ]
          },
          {
            name: 'Modern',
            options: [
              {
                text: 'Akos Major',
                value: 'by Akos Major'
              },
              {
                text: 'Andreas Levers',
                value: 'by Andreas Levers'
              },
              {
                text: 'Ed Freeman',
                value: 'by Ed Freeman'
              },
              {
                text: 'Erik Madigan Heck',
                value: 'by Erik Madigan Heck'
              },
              {
                text: 'Zhang Jingna',
                value: 'by Zhang Jingna'
              }
            ]
          }
        ],
        tag: 'select'
      }
    ]
  },
  {
    name: '',
    icon: 'tips_and_updates',
    fields: [
      {
        name: 'Lighting',
        icon: '',
        id: 'lighting',
        options: [
          {
            text: '--Select Lighting--',
            value: '$'
          },
          {
            text: 'Natural lighting',
            value: 'natural lighting'
          },
          {
            text: 'Ring Light',
            value: 'Ring Light'
          },
          {
            text: 'Flashguns',
            value: 'Flashguns lighting'
          },
          {
            name: 'Studio Lighting',
            options: [
              {
                text: 'studio lighting',
                value: 'studio lighting'
              },
              {
                text: 'Studio soft-light',
                value: 'beautiful studio soft light'
              },
              {
                text: 'Light diffusion',
                value: 'light diffusion'
              },
              {
                text: 'Cinematic lighting',
                value: 'Cinematic lighting'
              },
              {
                text: 'Cozy lighting',
                value: 'cozy lighting'
              },
              {
                text: 'direct flashlight',
                value: 'direct flashlight'
              },
              {
                text: 'neon light',
                value: 'neon light'
              },
              {
                text: 'Rim light',
                value: 'rim light'
              },
              {
                text: 'Softbox lighting',
                value: 'Softbox lighting'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Weather 1',
        icon: '',
        id: 'weather1',
        options: [
          {
            text: '--Select Weather--',
            value: '$'
          },
          {
            text: 'Natural lighting',
            value: 'natural lighting'
          },
          {
            text: 'Sunny',
            value: 'Sunny'
          },
          {
            text: 'Cloudy',
            value: 'Cloudy'
          },
          {
            text: 'Foggy',
            value: 'Foggy'
          },
          {
            text: 'Mist',
            value: 'mist'
          },
          {
            text: 'Rainy',
            value: 'rainy'
          },
          {
            text: 'Thunderstorm',
            value: 'thunderstorm'
          },
          {
            text: 'Storm',
            value: 'storm'
          },
          {
            text: 'Snowy',
            value: 'Snowy'
          },
          {
            text: 'Lightning',
            value: 'Lightning'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Weather 2',
        icon: '',
        id: 'weather2',
        options: [
          {
            text: '--Select Weather--',
            value: '$'
          },
          {
            text: 'Natural lighting',
            value: 'natural lighting'
          },
          {
            text: 'Sunny',
            value: 'Sunny'
          },
          {
            text: 'Cloudy',
            value: 'Cloudy'
          },
          {
            text: 'Foggy',
            value: 'Foggy'
          },
          {
            text: 'Mist',
            value: 'mist'
          },
          {
            text: 'Rainy',
            value: 'rainy'
          },
          {
            text: 'Thunderstorm',
            value: 'thunderstorm'
          },
          {
            text: 'Storm',
            value: 'storm'
          },
          {
            text: 'Snowy',
            value: 'Snowy'
          },
          {
            text: 'Lightning',
            value: 'Lightning'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Time of day',
        icon: '',
        id: 'day',
        options: [
          {
            text: '--Select Time of day--',
            value: '$'
          },
          {
            text: 'Sunrise',
            value: 'Sunrise'
          },
          {
            text: 'Morning',
            value: 'Morning'
          },
          {
            text: 'Afternoon',
            value: 'afternoon'
          },
          {
            text: 'Sunset',
            value: 'sunset'
          },
          {
            text: 'Evening',
            value: 'evening'
          },
          {
            text: 'Golden Hour',
            value: 'golden Hour'
          },
          {
            text: 'Twilight',
            value: 'twilight'
          },
          {
            text: 'Night',
            value: 'night'
          },
          {
            text: 'Midnight',
            value: 'midnight'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Specific Lighting',
        icon: '',
        id: 'light2',
        options: [
          {
            text: '--Select Specific Lighting--',
            value: '$'
          },
          {
            name: 'Ambiance',
            options: [
              {
                text: 'Dramatic light',
                value: 'dramatic light'
              },
              {
                text: 'Lens Flare',
                value: 'Lens Flare'
              },
              {
                text: 'Backlighting',
                value: 'Backlighting'
              },
              {
                text: 'Back sunlight',
                value: 'Back sunlight'
              },
              {
                text: 'Bright sunlight',
                value: 'bright sunlight'
              },
              {
                text: 'Crepuscular Rays',
                value: 'Crepuscular Rays'
              },
              {
                text: 'Dappled Light',
                value: 'Dappled Light'
              },
              {
                text: 'Dark dim lighting',
                value: 'Dark dim lighting'
              },
              {
                text: 'Dramatic light',
                value: 'dramatic light'
              },
              {
                text: 'Neon light',
                value: 'neon light'
              },
              {
                text: 'Red neon light',
                value: 'red neon light'
              },
              {
                text: 'Blue neon light',
                value: 'blue neon light'
              },
              {
                text: 'Silhouette lighting',
                value: 'silhouette lighting, luminous shadowing, contrasting lights and darks'
              },
              {
                text: 'Low-key lighting',
                value:
                  'clair-obscur, black and dark colors, Low-key lighting, rim light, Dark dim lighting'
              },
              {
                text: 'High-key lighting',
                value: 'white colors, High-key lighting, hight bright lighting'
              }
            ]
          },
          {
            name: 'Studio accessories',
            options: [
              {
                text: 'Silver Reflector',
                value: 'Silver Reflector'
              },
              {
                text: 'Gold Reflector',
                value: 'Gold Reflector'
              },
              {
                text: 'White Reflector',
                value: 'White Reflector'
              }
            ]
          }
        ],
        tag: 'select'
      }
    ]
  },
  {
    name: '',
    icon: 'center_focus_strong',
    fields: [
      {
        name: 'Face expression',
        icon: '',
        id: 'expression',
        options: [
          {
            text: '--Select expression--',
            value: '$'
          },
          {
            name: 'Positiv',
            options: [
              {
                text: 'Intense',
                value: 'experiencing something intense'
              },
              {
                text: 'Expressive',
                value: 'expressive'
              },
              {
                text: 'Smiling',
                value: 'happily surprised'
              },
              {
                text: 'Surprised',
                value: 'Angrily surprised'
              },
              {
                text: 'Playful smile',
                value: 'playful smile'
              },
              {
                text: 'Giggling',
                value: 'giggling'
              },
              {
                text: 'Laughing',
                value: 'Laughing'
              },
              {
                text: 'intensely excited face',
                value: 'intensely excited face'
              },
              {
                text: 'Joyful',
                value: 'Joyful'
              },
              {
                text: 'Pensive',
                value: 'Pensive'
              },
              {
                text: 'excited look on his face',
                value: 'excited look on his face'
              },
              {
                text: 'feeling very proud',
                value: 'feeling very proud'
              },
              {
                text: 'laughing wildly',
                value: 'laughing wildly'
              },
              {
                text: 'grimace',
                value: 'grimace'
              },
              {
                text: 'happy face',
                value: 'happy face'
              }
            ]
          },
          {
            name: 'Negativ',
            options: [
              {
                text: 'Morose',
                value: 'morose'
              },
              {
                text: 'Courage',
                value: 'courage'
              },
              {
                text: 'looking sad',
                value: 'looking sad'
              },
              {
                text: 'Fearful',
                value: 'Fearful'
              },
              {
                text: 'Furious',
                value: 'furious'
              },
              {
                text: 'Anger',
                value: 'anger'
              },
              {
                text: 'Shocked',
                value: 'Shocked'
              },
              {
                text: 'Confused',
                value: 'Confused'
              }
            ]
          }
        ],
        tag: 'select'
      },
      {
        name: 'Focus (beta)',
        icon: '',
        id: 'focus',
        options: [
          {
            text: '--Select focus--',
            value: '$'
          },
          {
            text: 'Eyes',
            value: 'focus on eyes'
          },
          {
            text: 'Mouth',
            value: 'focus on Mouth'
          },
          {
            text: 'face',
            value: 'focus on face'
          },
          {
            text: 'Tatoo',
            value: 'focus on tatoo'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Look to (beta)',
        icon: '',
        id: 'look',
        options: [
          {
            text: '--Select regard--',
            value: '$'
          },
          {
            text: 'Right',
            value: 'look to the left'
          },
          {
            text: 'Camera',
            value: 'look to the camera'
          },
          {
            text: 'Left',
            value: 'look to the right'
          },
          {
            text: 'Sky',
            value: 'look to the sky'
          }
        ],
        tag: 'select'
      },
      {
        name: 'View model (beta)',
        icon: '',
        id: 'viewmodel',
        options: [
          {
            text: '--Select view--',
            value: '$'
          },
          {
            text: 'Front view',
            value: 'front view'
          },
          {
            text: 'Left view',
            value: 'left side view'
          },
          {
            text: 'Right view',
            value: 'right side view'
          },
          {
            text: 'Back view',
            value: 'back view'
          },
          {
            text: 'Dynamic composition',
            value: 'Dynamic composition'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Skin and definition (beta)',
        icon: '',
        id: 'skin',
        options: [
          {
            text: '--Select option--',
            value: '$'
          },
          {
            text: 'Extreme detail',
            value: 'extreme detail and texture skin, morning glow, volumetric light'
          },
          {
            text: 'Insane details',
            value:
              'extreme detail and texture skin, insane details, intricate details, beautifully color graded'
          },
          {
            text: 'Detail',
            value:
              'accent Lighting, intricate detail,Colour Grading,intricate details, beautifully color graded, Color Grading'
          },
          {
            text: 'Colors',
            value:
              'white Balance, intricate details, beautifully color graded, Unreal Engine, Color Grading'
          }
        ],
        tag: 'select'
      }
    ]
  },
  {
    name: 'Logo',
    icon: '',
    fields: [
      {
        name: 'Logo of',
        icon: '',
        id: 't5a',
        options: [
          {
            text: '--Select Style--',
            value: '$'
          },
          {
            text: 'Business logo corporate name',
            value: 'business logo corporate name'
          },
          {
            text: 'Emblem logo',
            value: 'emblem logo of'
          },
          {
            text: 'Flat design logo',
            value: 'flat design logo of'
          },
          {
            text: 'Flat geometric vector graphic logo',
            value: 'flat geometric vector graphic logo of'
          },
          {
            text: 'Flat vector logo',
            value: 'flat vector logo of'
          },
          {
            text: 'Minimal line logo',
            value: 'minimal line logo of'
          },
          {
            text: 'Simple minimal logo',
            value: 'simple minimal logo of'
          },
          {
            text: 'Vector graphic logo',
            value: 'vector graphic logo of'
          },
          {
            text: 'Sharpie vector icon',
            value: 'Mini sharpie vector icon of'
          },
          {
            text: 'tiny doodle icon',
            value: 'tiny doodle icon of'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Logo style',
        icon: '',
        id: 't5aa',
        options: [
          {
            text: '--Select Style--',
            value: '$'
          },
          {
            text: 'Emblem logo',
            value: 'logo style, emblem'
          },
          {
            text: 'Mosaic style',
            value: 'mosaic style'
          },
          {
            text: 'Flat design logo',
            value: 'logo style, flat design'
          },
          {
            text: 'Flat geometric vector graphic logo',
            value: 'logo style, flat geometric vector graphic'
          },
          {
            text: 'Flat vector logo',
            value: 'logo style, flat vector'
          },
          {
            text: 'Minimal line logo',
            value: 'logo style, minimal line'
          },
          {
            text: 'Simple minimal logo',
            value: 'logo style, simple minimal'
          },
          {
            text: 'Vector graphic logo',
            value: 'logo style, vector graphic'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Design By',
        icon: '',
        id: 't5b',
        options: [
          {
            text: '--Select designer--',
            value: '$'
          },
          {
            text: 'Rob Janoff',
            value: 'by Rob Janoff'
          },
          {
            text: 'Sagi Haviv',
            value: 'by Sagi Haviv'
          },
          {
            text: 'Paul Rand',
            value: 'by Paul Rand'
          },
          {
            text: 'Ivan Chermayeff',
            value: 'by Ivan Chermayeff'
          },
          {
            text: 'Piet Mondrian',
            value: 'by Piet Mondrian'
          },
          {
            text: 'Egon Schiele',
            value: 'by Egon Schiele'
          },
          {
            text: 'Paul Klee',
            value: 'by Paul Klee'
          },
          {
            text: 'Pablo Picasso',
            value: 'by Pablo Picasso'
          },
          {
            text: 'Massimo Vignelli',
            value: 'by Massimo Vignelli'
          },
          {
            text: 'Saul Bass',
            value: 'by Saul Bass'
          },
          {
            text: 'Carolyn Davidson',
            value: 'by Carolyn Davidson'
          },
          {
            text: 'Steff Geissbuhler',
            value: 'by Steff Geissbuhler'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Background color',
        icon: '',
        id: 't5c',
        options: [
          {
            text: '--Select Style--',
            value: '$'
          },
          {
            text: 'White background',
            value: 'on a white background'
          },
          {
            text: 'Grey background',
            value: 'on a grey background'
          },
          {
            text: 'Black background',
            value: 'on a black background'
          },
          {
            text: 'Muted background',
            value: 'on muted background'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Color',
        icon: '',
        id: 't5d',
        options: [
          {
            text: '--Select Color--',
            value: '$'
          },
          {
            text: 'Single color',
            value: 'single color'
          },
          {
            text: 'Muted color',
            value: 'muted color'
          },
          {
            text: 'Warm color',
            value: 'warm color'
          },
          {
            text: 'Colorfull',
            value: 'colorfull'
          },
          {
            text: 'Black and white logo',
            value: 'Black and white logo'
          },
          {
            text: 'Blue and white logo',
            value: 'Blue and white logo'
          },
          {
            text: 'Red and white logo',
            value: 'Red and white logo'
          },
          {
            text: 'Gradient colour',
            value: 'Gradient colour scheme'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Line style',
        icon: '',
        id: 't5e',
        options: [
          {
            text: '--Select Line--',
            value: '$'
          },
          {
            text: 'Simple strokes',
            value:
              'simple strokes, Simple single-line structure, minimalist style, straight lines, curves'
          },
          {
            text: 'Thick outline',
            value: 'thick outline'
          },
          {
            text: 'Crisp linework',
            value: 'crisp linework'
          },
          {
            text: 'Black line drawing',
            value: 'black line drawing'
          },
          {
            text: 'Drawn by a child',
            value: 'black line drawing, tiny doodle, drawn by a child'
          },
          {
            text: 'Like vector paint',
            value: 'like vector paint'
          },
          {
            text: 'Water paint',
            value: 'water paint'
          },
          {
            text: 'simple curves strokes',
            value:
              'simple strokes, Simple single-line structure, minimalist style, straight lines, curves'
          }
        ],
        tag: 'select'
      }
    ]
  },
  {
    name: 'Comics',
    icon: '',
    fields: [
      {
        name: 'Comics',
        icon: '',
        id: 'comics',
        options: [
          {
            text: '--Select Style--',
            value: '$'
          },
          {
            text: 'Manga',
            value: 'Manga style'
          },
          {
            text: 'Comics book',
            value: 'comics book style'
          },
          {
            text: 'Comics book Art',
            value: 'comics book Art style'
          },
          {
            text: 'Cartoon comic',
            value: 'illustration, cartoon, comic'
          },
          {
            text: 'Anime',
            value: 'anime style'
          },
          {
            text: 'Cute anime',
            value: 'cute anime style'
          },
          {
            text: 'flat animation',
            value: 'flat animation style'
          },
          {
            text: 'Retro comics',
            value: 'digital illustration in the style of retro comics'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Colors and line',
        icon: '',
        id: 'comicscolors',
        options: [
          {
            text: '--Select option--',
            value: '$'
          },
          {
            text: 'inked manga',
            value: 'inked manga'
          },
          {
            text: 'ink drawing',
            value: 'ink drawing'
          },
          {
            text: 'flat illustration',
            value: 'flat illustration'
          },
          {
            text: 'sketch art',
            value: 'sketch art'
          },
          {
            text: 'retro comic book pointillism',
            value: 'retro comic book pointillism'
          },
          {
            text: 'Minimalist Monochrome',
            value: 'Minimalist Monochrome'
          },
          {
            text: 'Pop Art style',
            value: 'Pop Art Comics style'
          },
          {
            text: 'white outline',
            value: 'white outline'
          },
          {
            text: 'black outline',
            value: 'black outline'
          },
          {
            text: 'watercolor splash',
            value: 'watercolor splash'
          },
          {
            text: 'watercolor',
            value: 'watercolor'
          },
          {
            text: 'pastel drawing',
            value: 'pastel drawing'
          },
          {
            text: 'fantasy digital painting',
            value: 'fantasy digital painting'
          },
          {
            text: 'drawing line',
            value: 'drawing line'
          },
          {
            text: 'line art',
            value: 'line art'
          },
          {
            text: 'pen and ink',
            value: 'pen and ink'
          },
          {
            text: 'Line art',
            value: 'line art'
          }
        ],
        tag: 'select'
      },
      {
        name: 'Design By',
        icon: '',
        id: 'comicsdesign',
        options: [
          {
            text: '--Select designer--',
            value: '$'
          },
          {
            text: 'akira toriyama',
            value: 'by akira toriyama'
          },
          {
            text: 'Junji Ito',
            value: 'by Junji Ito'
          },
          {
            text: 'Akihiko Yoshida',
            value: 'by Akihiko Yoshida'
          },
          {
            text: 'Mamoru Nagano',
            value: 'by Mamoru Nagano'
          },
          {
            text: 'ivan laliashvili',
            value: 'by ivan laliashvili'
          },
          {
            text: 'Kentaro Miura',
            value: 'by Kentaro Miura'
          },
          {
            text: 'arcane',
            value: 'by Arcane'
          },
          {
            text: 'Mike Judge',
            value: 'by Mike Judge'
          },
          {
            text: 'Dustin Nguyen',
            value: 'by Dustin Nguyen'
          },
          {
            text: 'Roy Lichtenstein',
            value: 'by Roy Lichtenstein'
          },
          {
            text: 'Dave Gibbons',
            value: 'by Dave Gibbons'
          },
          {
            text: 'Steve Dillon',
            value: 'by Steve Dillon'
          },
          {
            text: 'Yoji Shinkawa',
            value: 'by Yoji Shinkawa'
          },
          {
            text: 'Frank Cho',
            value: 'by Frank Cho'
          },
          {
            text: 'Enki Bilal',
            value: 'by Enki Bilal'
          },
          {
            text: 'Carl Barks',
            value: 'by Carl Barks'
          },
          {
            text: 'Artgerm',
            value: 'by Artgerm'
          },
          {
            text: 'Neal Adams',
            value: 'by Neal Adams'
          },
          {
            text: 'Tim Sale',
            value: 'by Tim Sale'
          },
          {
            text: 'Hugo pratt',
            value: 'by Hugo pratt'
          },
          {
            text: 'Geof Darrow',
            value: 'by Geof Darrow'
          }
        ],
        tag: 'select'
      }
    ]
  }
];

export function addKeysToPanel(d) {
  d.rKey = shortid.generate();

  for (let j = 0; j < d.fields.length; j++) {
    d.fields[j].rKey = shortid.generate();

    if (d.fields[j].options) {
      for (let k = 0; k < d.fields[j].options.length; k++) {
        d.fields[j].options[k].rKey = shortid.generate();
        let x = d.fields[j].options[k];

        if (x.options) {
          for (let l = 0; l < x.options.length; l++) {
            x.options[l].rKey = shortid.generate();
          }
        }
      }
    }
  }
}

export async function populate() {
  for (let i = 0; i < data.length; i++) {
    addKeysToPanel(data[i]);
    const dc = doc(db, 'defaultPanels', i + '');
    await setDoc(dc, data[i]);
  }
}

// put the ids fro

export default data;
