import { createContext, useState } from 'react';
import { merge } from 'lodash';
import { MatxLayoutSettings } from 'app/components/MatxLayout/settings';

const SettingsContext = createContext({
  settings: MatxLayoutSettings,
  updateSettings: () => {}
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(settings || MatxLayoutSettings);

  const handleUpdateSettings = (update = {}) => {
    const marged = merge({}, currentSettings, update);
    setCurrentSettings(marged);
  };

  const addValue = (obj) => {
    const newSettings = { ...currentSettings };

    Object.keys(obj).forEach((key) => {
      newSettings[key] = obj[key];
    });
    setCurrentSettings(newSettings);
  };

  const replaceGenPanel = (genPanel) => {
    const newSettings = { ...currentSettings, genPanel };
    setCurrentSettings(newSettings);
  };

  const replacePanelCreator = (draft) => {
    const newSettings = { ...currentSettings, panelCreator: draft };
    setCurrentSettings(newSettings);
  };

  const replaceAllPanels = (panels) => {
    const newSettings = { ...currentSettings, panels };
    setCurrentSettings(newSettings);
  };

  const replaceAllPrompts = (prompts) => {
    const newSettings = { ...currentSettings, prompts };
    setCurrentSettings(newSettings);
  };

  const replaceAllPresets = (presets) => {
    presets.sort((a, b) => a.name.localeCompare(b.name));
    const newSettings = { ...currentSettings, presets };
    setCurrentSettings(newSettings);
  };

  const clearSettings = () => {
    setCurrentSettings(MatxLayoutSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        addSettingsValue: addValue,
        updateSettings: handleUpdateSettings,
        replaceGenPanel,
        replacePanelCreator,
        replaceAllPanels,
        replaceAllPrompts,
        replaceAllPresets,
        clearSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
