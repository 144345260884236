import {
  Alert,
  Box,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Icon,
  IconButton,
  Tooltip
} from '@mui/material';
import Loading from 'app/components/MatxLoading';
import useAuth from 'app/hooks/useAuth';
import { getDatabase, onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { DownloadForOffline, Upload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import download from 'downloadjs';
import { getBlob, getStorage, ref as storageRef } from 'firebase/storage';
import ImgsViewer from 'react-images-viewer';
import { useRecoilState } from 'recoil';
import { exifSelectionAtom } from './atom';
import ExifSelectorDialog from './ExifSelectorDialog';
import UploadToWordpressDialog from './UploadToWordpressDialog';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { getFunctions, httpsCallable } from 'firebase/functions';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired
};

function base64ToBlob(base64, mimeType) {
  const binaryString = atob(base64); // Decode the base64 string to binary data
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  // Convert the binary string to an array of bytes
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the byte array
  return new Blob([bytes], { type: mimeType });
}

const Waiting = ({ data }) => {
  if (data && (data.statusCode === 500 || data.statusCode === 401 || data.statusCode === 400)) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          padding: '20px',
          height: '70vh',
          flexDirection: 'column'
        }}
      >
        <Icon style={{ fontSize: '100px', color: 'red' }}>error</Icon>
        <Alert severity="error">
          Task Failed<br></br> {data.status}
        </Alert>
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '20px',
        height: '70vh'
      }}
    >
      {data === null && <Alert severity="error">Invalid Tracker ID</Alert>}
      {data !== null && !data.status && <Loading />}

      {data && data.status && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
            flexDirection: 'column'
          }}
        >
          {data.progress !== undefined && data.progress > 0 && data.progress < 1 && (
            <CircularProgressWithLabel value={data.progress * 100} />
          )}
          {(data.progress === undefined || data.progress === 0 || data.progress === 1) && (
            <CircularProgress />
          )}

          <Typography
            style={{ marginTop: '15px' }}
            variant="h6"
            component="div"
            color="text.secondary"
          >
            {data.status}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const Tracker = () => {
  const { trackID } = useParams();
  const [data, setData] = useState({});
  const { user } = useAuth();
  const [currImg, setCurrImg] = useState(null);
  const [nowLoading, setNowLoading] = useState([]);
  const [exifSelection, setExifSelection] = useRecoilState(exifSelectionAtom);
  const [images, setImages] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [imgUploadOpen, setImgUploadOpen] = useState(false);
  const [sites, setSites] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loadingSites, setLoadingSites] = useState(true);

  useEffect(() => {
    if (!trackID || !user || !trackID.includes('-')) {
      setData(null);
      return;
    }
    const db = getDatabase();
    const dt = trackID.split('-');
    const starCountRef = ref(db, `${dt[1]}/${user.uid}/${dt[0]}`);

    const unsub = onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setData(data);
      if (data && data.images) {
        setImages(Object.keys(data.images).map((key) => data.images[key]));
      }
    });

    const fetchSites = async () => {
      const res = await getDocs(
        collection(getFirestore(), 'userData', user.uid, 'wordpress_sites')
      );
      let nSites = [];
      res.docs.forEach((doc) => {
        nSites.push({ ...doc.data(), id: doc.id });
      });

      setSites(nSites);
      setLoadingSites(false);
    };

    fetchSites();

    return () => {
      unsub();
    };
  }, []);

  const doWebDownload = async (imgSrc, exif) => {
    setIsWorking(true);

    try {
      const fn = httpsCallable(getFunctions(), 'userTask');
      const res = await fn({
        command: 'addExifToImage',
        imageUrl: imgSrc,
        exif
      });

      let img = res.data?.img;
      img = base64ToBlob(img, 'image/jpeg');

      let filename = 'Untitled - AlphonsePrompt';
      if (exif?.Filename) {
        filename = exif.Filename;
      } else if (exif?.Title) {
        filename = exif.Title.toLowerCase()
          .replace(/ +/g, '-')
          .replace(/[^a-z0-9-áéíóúÁÉÍÓÚ]/g, '');
      } else if (data.subject) {
        filename = data.subject
          .toLowerCase()
          .replace(/ +/g, '-')
          .replace(/[^a-z0-9-áéíóúÁÉÍÓÚ]/g, '');
      }

      download(img, filename, 'image/jpeg');
    } catch (e) {
      console.log(e);
      toast.error('Failed to download the image');
    }

    setIsWorking(false);
  };

  const uploadToWordpress = async (images, sites, exif) => {
    toast.success('Task Queued for Uploading to WordPress');
    try {
      const dt = trackID.split('-');
      const updateRef = `${dt[1]}/${user.uid}/${dt[0]}`;
      const fn = httpsCallable(getFunctions(), 'userTask');
      const obj = {
        command: 'uploadToWordPress',
        images,
        sites,
        exif,
        updateRef
      };
      console.log(obj);

      const res = await fn(obj);
      // console.log(res);
    } catch (e) {
      console.log(e);
      toast.error('Failed to upload images to WordPress');
    }
  };

  const initiateWEBDownload = async (imgSrc) => {
    setExifSelection((prev) => ({
      ...prev,
      open: true,
      nextCallback: (exif) => {
        doWebDownload(imgSrc, exif);
      }
    }));
  };

  const selectExitForUpload = async (images, sites) => {
    setImgUploadOpen(false);
    setExifSelection((prev) => ({
      ...prev,
      open: true,
      nextCallback: (exif) => {
        uploadToWordpress(images, sites, exif);
      }
    }));
  };

  if (!data || !data.images) return <Waiting data={data} />;

  return (
    <Box m={{ xs: 2, md: 3 }}>
      <Dialog open={isWorking}>
        <DialogContent>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}
          >
            <CircularProgress />
            <Typography variant="body2" component="div" color="text.secondary">
              downloading image...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <UploadToWordpressDialog
        loadingSites={loadingSites}
        open={imgUploadOpen}
        setOpen={setImgUploadOpen}
        images={images}
        next={selectExitForUpload}
        sites={sites}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
      />
      <ExifSelectorDialog subject={data.subject} />
      <ImgsViewer
        imgs={images.map((img) => ({ src: img }))}
        currImg={currImg}
        onClickPrev={() => setCurrImg((((currImg - 1) % 4) + 4) % 4)}
        onClickNext={() => setCurrImg((currImg + 1) % 4)}
        isOpen={currImg !== null}
        onClose={() => setCurrImg(null)}
        showCloseBtn={false}
        showThumbnails={true}
        backdropCloseable={true}
        onClickThumbnail={(index) => setCurrImg(index)}
      />
      <Grid
        container
        rowSpacing={{ xs: 2, md: 4 }}
        columnSpacing={{ xs: 2, md: 4 }}
        justifyContent={'center'}
      >
        {data.WPuploadStatus && (
          <Grid item xs={10}>
            <Alert severity="info">
              <strong>Wordpress Task:</strong> {data.WPuploadStatus}
            </Alert>
          </Grid>
        )}
        {images.map((img, index) => (
          <Grid item xs={11} md={5} key={index + ''}>
            <Card>
              <CardMedia
                onClick={() => setCurrImg(index)}
                style={{ height: 100, paddingTop: '56.25%' }}
                image={data.images[`img${index + 1}`]}
              />
              <div>
                <div
                  style={{
                    display: 'flex',
                    padding: '8px',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      gap: '5px'
                    }}
                  >
                    <Tooltip title="Coming soon ..." enterTouchDelay={0} placement="top">
                      <IconButton>
                        <Tooltip title="variant" enterTouchDelay={0} placement="bottom">
                          <Icon>call_split</Icon>
                        </Tooltip>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Coming soon ..." enterTouchDelay={0} placement="top">
                      <IconButton>
                        <Tooltip title="upscale" enterTouchDelay={0} placement="bottom">
                          <Icon>arrow_circle_up</Icon>
                        </Tooltip>
                      </IconButton>
                    </Tooltip>

                    <IconButton color="primary" onClick={() => initiateWEBDownload(img)}>
                      <Tooltip title="Download as JPEG" enterTouchDelay={0} placement="bottom">
                        <Icon>web</Icon>
                      </Tooltip>
                    </IconButton>
                  </div>
                  <IconButton
                    title="Upload to WordPress"
                    color="primary"
                    onClick={() => {
                      if (!selectedImages.includes(img)) {
                        setSelectedImages((p) => [...p, img]);
                      }
                      setImgUploadOpen(true);
                    }}
                  >
                    <img
                      src="/assets/images/wordpress.svg"
                      alt="img"
                      style={{ width: '28px', height: '28px' }}
                    />
                  </IconButton>

                  <LoadingButton
                    title="Download"
                    loading={nowLoading.includes(index)}
                    disabled={nowLoading.includes(index)}
                    style={{ zIndex: 100 }}
                    onClick={async (e) => {
                      e.stopPropagation();
                      setNowLoading([...nowLoading, index]);
                      let img = data.images[`img${index + 1}`];
                      const sref = storageRef(getStorage(), img);
                      let imgBlob = await getBlob(sref);

                      let filename = 'Untitled - AlphonsePrompt';
                      if (exifSelection.exif?.Filename) {
                        filename = exifSelection.exif.Filename;
                      } else if (exifSelection.exif?.Title) {
                        filename = exifSelection.exif.Title.toLowerCase()
                          .replace(/ +/g, '-')
                          .replace(/[^a-z0-9-áéíóúÁÉÍÓÚ]/g, '');
                      } else if (data.subject) {
                        filename = data.subject
                          .toLowerCase()
                          .replace(/ +/g, '-')
                          .replace(/[^a-z0-9-áéíóúÁÉÍÓÚ]/g, '');
                      }

                      download(imgBlob, filename, 'image/jpeg');
                      setNowLoading(nowLoading.filter((i) => i !== index));
                    }}
                  >
                    <DownloadForOffline style={{ fontSize: '28px' }} />
                  </LoadingButton>
                </div>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Tracker;
